import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { toast } from "react-toastify";
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import { db } from "../../firebase";

function CreateVariant() {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };

  // Starts from here
  const [category, setCategory] = useState();
  const [inputData, setInputData] = useState([{ variant: "", price: "" }]);
  const [loading, setLoading] = useState(false);
  const handleAdd = (e) => {
    e.preventDefault();
    setInputData([...inputData, { variant: "", price: "" }]);
  };

  const handleRemove = (i) => {
    const list = [...inputData];
    list.splice(i, 1);
    setInputData(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputData];
    list[index][name] = value;
    setInputData(list);
  };

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const dataCollectionRef = collection(db, "prices");
      await addDoc(dataCollectionRef, {
        name: category,
        prices: inputData,
        timestamp: dateTime,
      });
      toast.success("Form submitted successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className="flex flex-row h-screen">
      <Sidebar active={active} />
      <div className="flex-auto bg-gray-50 overflow-auto">
        <Navbar handleActive={handleActive} />
        <form onSubmit={handleSubmit} className="m-10">
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="col-span-6 sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                  Category <span className="text-red-500">*</span>
                </label>
                <select
                  onChange={(e) => setCategory(e.target.value)}
                  className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                >
                  <option>Select Vehicle</option>
                  <optgroup label="Arena">
                    <option value="Alto 800">Maruti Alto</option>
                    <option value="Alto K10">Maruti Alto K10</option>
                    <option value="Wagon R">Maruti Wagon R</option>
                    <option value="Celerio">Maruti New Celerio</option>
                    <option value="Swift">Maruti Swift</option>
                    <option value="Dzire">Maruti Dzire</option>
                    <option value="S-Presso">Maruti S-Presso</option>
                    <option value="Ertiga">Maruti Ertiga</option>
                    <option value="Brezza">Maruti New Brezza</option>
                    <option value="Eeco">Maruti Eeco</option>
                  </optgroup>
                  <optgroup label="Nexa">
                    <option value="Invicto">Maruti Invicto</option>
                    <option value="Fronx">Maruti Fronx</option>
                    <option value="Jimny">Maruti Jimny</option>
                    <option value="Ciaz">Maruti Ciaz</option>
                    <option value="XL6">Maruti XL6</option>
                    <option value="Baleno">Maruti Baleno</option>
                    <option value="Ignis">Maruti Ignis</option>
                    <option value="Grand Vitara">Maruti Grand Vitara</option>
                    <option value="Baleno CNG">Baleno CNG</option>
                    <option value="Xl6 CNG">Xl6 CNG</option>
                    <option value="Grand Vitara CNG">Grand Vitara CNG</option>
                  </optgroup>
                  <optgroup label="Passenger Carrier">
                    <option value="Tour H1">Maruti Tour H1</option>
                    <option value="Tour H3">Maruti Tour H3</option>
                    <option value="Tour S">Maruti Tour S</option>
                    <option value="Tour V">Maruti Tour V</option>
                    <option value="Tour M">Maruti Tour M</option>
                    <option value="Carry">Maruti Super Carry</option>
                    <option value="Cargo">Maruti EECO Cargo</option>
                  </optgroup>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-5 px-4 py-5 bg-white sm:p-6">
              {inputData.map((x, i) => (
                <>
                  <div className="col-span-2 mr-4 mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Variant <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleInputChange(e, i)}
                      name="variant"
                      value={x.variant}
                      className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-2 mr-4 mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Price <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleInputChange(e, i)}
                      name="price"
                      value={x.price}
                      className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-1 space-x-3 mt-2">
                    {inputData.length !== 1 && (
                      <button
                        type="submit"
                        onClick={() => handleRemove(i)}
                        className="inline-flex justify-center py-3 px-5 mt-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                      >
                        Remove
                      </button>
                    )}
                    {inputData.length - 1 === i && (
                      <button
                        type="submit"
                        onClick={handleAdd}
                        className="inline-flex justify-center py-3 px-5 mt-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
                      >
                        Add More
                      </button>
                    )}
                  </div>
                </>
              ))}
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <CgSpinner className="animate-spin h-5 mr-2 text-white w-5" />
                    Loading...
                  </div>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateVariant;
