import { collection, getDocs, query } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { CgDollar } from "react-icons/cg";
import { FaPhone } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { toast } from "react-toastify";
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import CustomLoader from "../../components/CustomLoader";
import { db } from "../../firebase";

function Dashboard() {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };
  const [leads, setLeads] = useState();
  const [finance, setFinance] = useState();
  const [truevalue, setTruevalue] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        //leads
        const collectionRef = collection(db, "enquiries");
        const q = query(collectionRef);
        const querySnapshot = await getDocs(q);
        setLeads(querySnapshot.size);

        //finance
        const collectionFinanceRef = collection(db, "finances");
        const qF = query(collectionFinanceRef);
        const querySnapshotFinance = await getDocs(qF);
        setFinance(querySnapshotFinance.size);
        //truevalue

        const collectionTruevalueRef = collection(db, "ptruevalues");
        const qT = query(collectionTruevalueRef);
        const querySnapshotTruevalue = await getDocs(qT);
        setTruevalue(querySnapshotTruevalue.size);
      } catch (error) {
        toast.error("Something went wrong!");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-row h-screen">
        <Sidebar active={active} />
        <div className="flex-auto bg-gray-50 overflow-auto">
          <Navbar handleActive={handleActive} />
          {loading ? (
            <CustomLoader />
          ) : (
            <div className="mx-10 grid grid-cols-3 container gap-5 mt-10">
              <div className="col-span-1 border text-white bg-purple-600 flex items-center justify-between border-gray-300 rounded p-5">
                <div className="">
                  <p className="text-white text-lg">Leads</p>
                  <p className="text-lg font-semibold">{leads}</p>
                </div>

                <FaPhone className="h-6 w-6" />
              </div>

              <div className="col-span-1 border text-white bg-red-600 border-gray-300 rounded p-5 flex items-center justify-between">
                <div className="">
                  <p className="text-white text-lg">Finance</p>
                  <p className="text-lg font-semibold">{finance}</p>
                </div>

                <CgDollar className="h-6 w-6" />
              </div>

              <div className="col-span-1 border text-white  bg-green-500 border-gray-300 rounded p-5 flex items-center justify-between">
                <div>
                  <p className="text-lg">Truevalue</p>
                  <p className="text-lg font-semibold">{truevalue}</p>
                </div>
                <TiTick className="h-8 w-8" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
