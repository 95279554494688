import React from "react";
import { FaRegEye } from "react-icons/fa";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { TbNotes } from "react-icons/tb";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <div className="container mx-auto mt-16 mb-8 px-5 md:px-0">
        <div className="flex justify-center">
          <p className="bg-purple-900 p-2 text-white">
            Welcome to Pavan Motors
          </p>
        </div>
        <p className="uppercase font-semibold text-4xl text-center mt-3">
          Car Dealership
        </p>
        <div className="relative">
          <div className="h-1 bg-gray-500 mt-3 max-w-[100px] mx-auto"></div>
        </div>
        <p className="mt-8 max-w-3xl text-center mx-auto">
          PAVAN MOTORS has a Maruti Suzuki car dealership showroom in Hyderabad
          with end-to-end car sales and service.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-5">
          <div className="col-span-1 bg-gray-100 hover:bg-gray-200 duration-300 text-center">
            <div className="p-8">
              <FaRegEye className="mx-auto text-red-700" size={45} />
              <p className="text-xl mt-5">Our Vision</p>
              <p className="font-light mt-2">
                We pavan motors to reach customers in all possible ways, to
                fulfill to buy the dream car.
              </p>
            </div>
            <div className="flex">
              <Link
                to="/about"
                className="bg-purple-900 text-white w-full py-3"
              >
                Read More
              </Link>
            </div>
          </div>

          <div className="col-span-1 bg-gray-100 hover:bg-gray-200 duration-300 text-center">
            <div className="p-8">
              <BiMessageRoundedDetail
                className="mx-auto text-red-700"
                size={45}
              />
              <p className="text-xl mt-5">Objectives</p>
              <p className="font-light mt-2">
                To ensure customer delight ness in all the ways ( Sales,
                Service, Finance, Exchange).
              </p>
            </div>
            <div className="flex">
              <Link
                to="/about"
                className="bg-purple-900 text-white w-full py-3"
              >
                Read More
              </Link>
            </div>
          </div>

          <div className="col-span-1 bg-gray-100 hover:bg-gray-200 duration-300 text-center">
            <div className="p-8">
              <BsPerson className="mx-auto text-red-700" size={45} />
              <p className="text-xl mt-5">Our Mission</p>
              <p className="font-light mt-2">
                One among Top dealers in Telangana state.
              </p>
            </div>
            <div className="flex">
              <Link
                to="/about"
                className="bg-purple-900 text-white w-full py-3"
              >
                Read More
              </Link>
            </div>
          </div>

          <div className="col-span-1 bg-gray-100 hover:bg-gray-200 duration-300 text-center">
            <div className="p-8">
              <TbNotes className="mx-auto text-red-700" size={45} />
              <p className="text-xl mt-5">Our Values</p>
              <p className="font-light mt-2">Centric approach.</p>
            </div>
            <div className="flex">
              <Link
                to="/about"
                className="bg-purple-900 text-white w-full py-3"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="about-bg">
        <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto my-7">
          <div className="col-span-1 px-5 md:px-0">
            <p className="uppercase font-semibold text-3xl">About us</p>
            <p className="mt-8">
              We Pavan Motors – an Authorized Dealership for Maruti Suzuki
              provides “Truly Exceptional” moments of buying & servicing a
              vehicle since one decade (2011). We are very passionate about
              customer delightness by serving the valued customers in Sales &
              Service. Take great pride in creating, what we believe, to be one
              of the most premier dealerships across Telangana State.
            </p>
            <div className="flex items-center space-x-3 my-5">
              <img
                src={require("../../assets/pic2.jpeg")}
                className="rounded-full"
                alt="pic"
              />
              <div className="uppercase ">
                <p className="text-red-600 font-medium text-xl">
                  Have any question ?
                </p>
                <a
                  href="tel:7093711199"
                  className="text-2xl tracking-wide hover:text-red-600 duration-300 font-semibold"
                >
                  7093 7111 99
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <img
              src={require("../../assets/nexa/grandvitara.webp")}
              className="mx-auto xl:mt-20"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
