import React from "react";
import { CgSpinner } from "react-icons/cg";

function CustomLoader() {
  return (
    <CgSpinner
      className="animate-spin flex mx-auto"
      size={50}
      color="#7e22ce"
    />
  );
}

export default CustomLoader;
