import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { toast } from "react-toastify";
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import { db, storage } from "../../firebase";

function CreateSlider() {
  const [active, setActive] = useState(true);
  const [photos, setPhotos] = useState([]);
  const [urls, setUrls] = useState([]);
  const [categories, setCategories] = useState([]);
  const [progress, setProgress] = useState(0);
  const [category, setCategory] = useState("");

  const handlePhotoChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setPhotos((prev) => [...prev, newImage]);
    }
  };

  const handleActive = () => {
    setActive(!active);
  };

  const handleUpload = () => {
    const promises = [];
    photos.map((image) => {
      const name = new Date().getTime() + image.name;
      const storageRef = ref(storage, `vehicles/${name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setUrls((prev) => [...prev, url]);
          });
        }
      );
    });

    Promise.all(promises)
      .then(() => {
        toast.success("All images are uploaded");
        setPhotos([]);
      })
      .catch((err) => console.log(err));
  };

  const dataCollectionRef = collection(db, "vehicleImages");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(dataCollectionRef, {
        category: category,
        images: urls,
      });
      toast.success("Vehicle added successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <div className="flex flex-row h-screen">
        <Sidebar active={active} />
        <div className="flex-auto bg-gray-50 overflow-auto">
          <Navbar handleActive={handleActive} />
          <p className="font-semibold text-xl ml-10 my-6">Upload Images</p>
          <div className="m-10">
            <form onSubmit={handleSubmit}>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <p className="text-red-500 text-xs mb-5">
                    Note:{" "}
                    <span className="text-gray-600">
                      Wait for the image to upload
                    </span>
                  </p>
                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                      Category <span className="text-red-500">*</span>
                    </label>
                    <select
                      onChange={(e) => setCategory(e.target.value)}
                      className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    >
                      <option>Select Vehicle</option>
                      <optgroup label="Arena">
                        <option value="Alto 800">Maruti Alto</option>
                        <option value="Alto K10">Maruti Alto K10</option>
                        <option value="Wagon R">Maruti Wagon R</option>
                        <option value="Celerio">Maruti New Celerio</option>
                        <option value="Swift">Maruti Swift</option>
                        <option value="Dzire">Maruti Dzire</option>
                        <option value="S-Presso">Maruti S-Presso</option>
                        <option value="Ertiga">Maruti Ertiga</option>
                        <option value="Brezza">Maruti New Brezza</option>
                        <option value="Eeco">Maruti Eeco</option>
                      </optgroup>
                      <optgroup label="Nexa">
                        <option value="Ciaz">Maruti Ciaz</option>
                        <option value="XL6">Maruti XL6</option>
                        {/* <option value="S-Cross">Maruti S-Cross</option> */}
                        <option value="Baleno">Maruti Baleno</option>
                        <option value="Ignis">Maruti Ignis</option>
                        <option value="Grand Vitara">
                          Maruti Grand Vitara
                        </option>
                      </optgroup>
                      <optgroup label="Passenger Carrier">
                        <option value="Tour H1">Maruti Tour H1</option>
                        <option value="Tour H3">Maruti Tour H3</option>
                        <option value="Tour S">Maruti Tour S</option>
                        <option value="Tour V">Maruti Tour V</option>
                        <option value="Tour M">Maruti Tour M</option>
                      </optgroup>
                    </select>
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Photos <span className="text-red-500">*</span>
                    </label>

                    <input
                      type="file"
                      multiple
                      onChange={(e) => handlePhotoChange(e)}
                      className="p-3 resize-none border shadow-sm sm:texr-sm border-gray-300 rounded-md w-full focus:outline-none outline-none"
                    />
                  </div>

                  <button
                    onClick={() => handleUpload()}
                    type="button"
                    className="inline-flex justify-center mt-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Upload
                  </button>
                </div>
                <div className="flex items-center justify-center flex-row flex-grow">
                  {urls?.length > 0 &&
                    urls.map((url, i) => (
                      <img src={url} className="h-40" alt="" key={i} />
                    ))}
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateSlider;
