import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { BsEnvelopeFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { ImLocation } from "react-icons/im";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import { Helmet } from "react-helmet";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const pattern = /^[6-9][0-9]{6,9}$/;
  if (phone !== "" && phone.length === 10) {
    if (!pattern.test(phone)) {
      toast.error("Enter valid phone number", {
        theme: "colored",
      });
    }
  }
  let navigate = useNavigate();

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name !== "" && phone !== "" && message !== "") {
      setLoader(true);
      const dataCollectionRef = collection(db, "enquiries");
      try {
        await addDoc(dataCollectionRef, {
          name: name,
          email: email,
          phone: phone,
          message: message,
          timestamp: dateTime,
        });
        toast.success("Form submitted successfully");
        setLoader(false);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        navigate("/thank-you");
      } catch (error) {
        toast.error("Something went wrong!");
      }
      setLoader(false);
    } else {
      toast.error("Please fill all the fields");
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Pavan Motors| Contact us for any Maruti Car Sales, Feedback & Complaints  "
        />
        <meta
          name="description"
          content="Contact Pavan Motors Dealers for any Maruti Suzuki vehicle enquiry. For queries about any of the Maruti cars, feedback or complaints."
        />
        <meta
          name="keywords"
          content="car showrooms, Pavan motors contact us,"
        />
      </Helmet>
      <Header />
      <img
        src={require("../assets/banners/Makers_Of_Suv_Web.jpeg")}
        className="w-full lg:h-[590px] object-cover"
        alt="contact banner"
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / Contact
        </p>
      </div>

      <div className="container mx-auto mb-8">
        <div className="p-7">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-7 mb-12">
            <div className="flex flex-col items-center p-5 rounded space-y-3 justify-center border border-gray-200">
              <BsEnvelopeFill size={48} className="text-red-600" />
              <p className="uppercase text-[#412236] font-semibold">
                Send Email
              </p>
              <a href="mailto:info@pavanmotors.com">info@pavanmotors.com </a>
            </div>
            <a
              href="tel:7093711199"
              className="flex flex-col items-center p-5 rounded space-y-3 justify-center border border-gray-200"
            >
              <BsTelephoneFill size={48} className="text-red-600" />
              <p className="uppercase text-[#412236] font-semibold">Call us</p>
              <p>+91 7093 7111 99</p>
            </a>
            <div className="flex flex-col items-center p-5 rounded space-y-3 justify-center border border-gray-200">
              <ImLocation size={48} className="text-red-600" />
              <p className="uppercase text-[#412236] font-semibold">Location</p>
              <p className="text-center">
                Plot No 5,6,7,8&9, Doyens Colony, Serilingampally, Rangareddy,
                Hyderabad, Telangana 500020.
              </p>
            </div>
          </div>
          <form className="space-y-5" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div>
                <input
                  type="text"
                  className="w-full bg-gray-100 font-medium outline-none p-3 rounded"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="email"
                  value={email}
                  className="w-full bg-gray-100 font-medium outline-none p-3 rounded"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  className="w-full bg-gray-100 font-medium outline-none p-3 rounded"
                  placeholder="Phone"
                  onChange={(e) =>
                    setPhone(
                      e.target.value.replace(/[^1-9 ]/g, "") &&
                        e.target.value.replace(/ /g, "")
                    )
                  }
                  value={phone}
                  minLength="10"
                  maxLength="10"
                />
                {!pattern.test(phone) && phone.length === 10 ? (
                  <small className="text-red-500">
                    phone number is invalid
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <textarea
              className="w-full outline-none resize-none font-medium bg-gray-100 rounded p-3 col-span-3"
              placeholder="Enter Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={7}
            ></textarea>
            <button className="mx-auto uppercase flex rounded-full bg-purple-800 hover:bg-green-700 duration-300 text-lg text-white px-6 py-2">
              {loader ? (
                <div className="flex items-center justify-center">
                  <CgSpinner className="animate-spin h-5 mr-2 text-white w-5" />
                  Loading
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </form>
          <iframe
            title="map"
            className="w-full h-[400px] mt-16"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.8047727881253!2d78.32908001487743!3d17.46905578802981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9334b7f8f217%3A0x813fc9606cc7a0b7!2sMaruti%20Suzuki%20ARENA%20(Pavan%20Motors%2C%20Sherlingampally)!5e0!3m2!1sen!2sin!4v1627904934870!5m2!1sen!2sin"
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
