export const homeScreenCars = {
  arena: [
    {
      name: "Maruti Alto",
      nick: "Alto 800",
      img: require("../assets/arena/alto.webp"),
      price: "3,39,000",
      kmpl: "22.05",
      seats: "5",
      engine: "796",
      link: "/maruti-suzuki-alto-price",
    },
    {
      name: "Alto K10",
      nick: "Alto K10",
      img: require("../assets/arena/k10.webp"),
      price: "3,99,000",
      kmpl: "24.90",
      seats: "5",
      engine: "998",
      link: "/maruti-suzuki-alto-k10-price",
    },
    {
      name: "Maruti Brezza",
      nick: "Brezza",
      img: require("../assets/arena/brezza.webp"),
      price: "7,99,000",
      kmpl: "17.05",
      seats: "5",
      engine: "1462",
      link: "/maruti-suzuki-brezza-price",
    },
    {
      name: "Maruti Celerio",
      nick: "Celerio",
      img: require("../assets/arena/celerio.webp"),
      price: "5,25,000",
      kmpl: "24.97",
      seats: "5",
      engine: "998",
      link: "/maruti-suzuki-celerio-price",
    },
    {
      name: "Maruti Dzire",
      nick: "Dzire",
      img: require("../assets/arena/dzire.webp"),
      price: "6,24,000",
      kmpl: "23.26",
      seats: "5",
      engine: "1197",
      link: "/maruti-suzuki-dzire-price",
    },
    {
      name: "Maruti EECO",
      nick: "Eeco",
      img: require("../assets/arena/eeco.webp"),
      price: "4,63,000",
      kmpl: "16.11",
      seats: "5",
      engine: "1196",
      link: "/maruti-suzuki-eeco-price",
    },
    {
      name: "Maruti Ertiga",
      nick: "Ertiga",
      img: require("../assets/arena/ertiga.png"),
      price: "8,35,000",
      kmpl: "20.51",
      seats: "7",
      engine: "1462",
      link: "/maruti-suzuki-ertiga-price",
    },
    {
      name: "Maruti S-Presso",
      nick: "S-Presso",
      img: require("../assets/arena/Spresso.webp"),
      price: "4,25,000",
      kmpl: "25.30",
      seats: "5",
      engine: "998",
      link: "/maruti-suzuki-spresso-price",
    },
    {
      name: "Maruti Swift",
      nick: "Swift",
      img: require("../assets/arena/swift.webp"),
      price: "5,25,000",
      kmpl: "23.76",
      seats: "5",
      engine: "1197",
      link: "/maruti-suzuki-swift-price",
    },
    {
      name: "Maruti Wagon R",
      nick: "Wagon R",
      img: require("../assets/arena/wagonr.png"),
      price: "5,47,000",
      kmpl: "17.03",
      seats: "5",
      engine: "1197",
      link: "/maruti-suzuki-wagonr-price",
    },
  ],
  nexa: [
    {
      name: "Maruti Invicto",
      nick: "Invicto",
      img: require("../assets/nexa/invicto.webp"),
      price: "24,79,000",
      kmpl: " ",
      seats: " ",
      engine: " ",
      link: "/invicto",
    },
    {
      name: "Maruti Fronx",
      nick: "Fronx",
      img: require("../assets/nexa/fronx.png"),
      price: "7,46,500",
      kmpl: " ",
      seats: " ",
      engine: " ",
      link: "/fronx",
    },
    {
      name: "Maruti Jimny",
      nick: "Jimny",
      img: require("../assets/nexa/jimny.png"),
      price: "12,74,000",
      kmpl: " ",
      seats: " ",
      engine: " ",
      link: "/jimny",
    },
    {
      name: "Maruti Baleno",
      nick: "Baleno",
      img: require("../assets/nexa/baleno.png"),
      price: "6,49,000",
      kmpl: "22.94",
      seats: "5",
      engine: "1197",
      link: "/baleno",
    },
    {
      name: "Maruti Ciaz",
      nick: "Ciaz",
      img: require("../assets/nexa/ciaz.webp"),
      price: "8,99,500",
      kmpl: "20.65",
      seats: "5",
      engine: "1462",
      link: "/ciaz",
    },
    {
      name: "Maruti XL6",
      nick: "XL6",
      img: require("../assets/nexa/xl6.webp"),
      price: "11,29,000",
      kmpl: "20.97",
      seats: "6",
      engine: "1462",
      link: "/xl6",
    },
    {
      name: "Maruti Ignis",
      nick: "Ignis",
      img: require("../assets/nexa/ignis.webp"),
      price: "5,35,000",
      kmpl: "20.89",
      seats: "5",
      engine: "1197",
      link: "/ignis",
    },
    // {
    //   name: "Maruti S-Cross",
    //   img: require("../assets/nexa/scross.webp"),
    //   price: "8,95,000",
    //   kmpl: "18.55",
    //   seats: "5",
    //   engine: "1462",
    //   link: "/scross",
    // },
    {
      name: "Maruti Grand Vitara",
      nick: "Grand Vitara",
      img: require("../assets/nexa/grandvitara.webp"),
      price: "10,45,00",
      kmpl: "-",
      seats: "5",
      engine: "1462",
      link: "/grandvitara",
    },
  ],
  tour: [
    {
      name: "Tour H1",
      nick: "Tour H1",
      img: require("../assets/tour/Tour_H1.jpeg"),
      price: "4,05,000",
      kmpl: "22.05",
      seats: "5",
      engine: "796",
      link: "/tourh1",
    },
    {
      name: "Tour H3",
      nick: "Tour H3",
      img: require("../assets/tour/Tour_H3.jpeg"),
      price: "5,44,000",
      kmpl: "24.5",
      seats: "5",
      engine: "998",
      link: "/tourh3",
    },
    {
      name: "Tour M",
      nick: "Tour M",
      img: require("../assets/tour/Tour_M.jpeg"),
      price: "9,46,000",
      kmpl: "18.04",
      seats: "7",
      engine: "1462",
      link: "/tourm",
    },
    {
      name: "Tour S",
      nick: "Tour S",
      img: require("../assets/tour/Tour_S.jpeg"),
      price: "6,21,000",
      kmpl: "19.95",
      seats: "5",
      engine: "1197",
      link: "/tours",
    },
    {
      name: "Tour V",
      nick: "Tour V",
      img: require("../assets/tour/Tour_V.jpeg"),
      price: "4,60,200",
      kmpl: "16.11",
      seats: "5",
      engine: "1196",
      link: "/tourv",
    },
    {
      name: "Super Carry",
      nick: "Carry",
      img: require("../assets/goods/carry.webp"),
      price: "4,73,000",
      kmpl: "-",
      seats: "-",
      engine: "1196",
      link: "/super-carry",
    },
    {
      name: "EECO Cargo",
      nick: "Cargo",
      img: require("../assets/goods/cargo.webp"),
      price: "4,78,200",
      kmpl: "-",
      seats: "-",
      engine: "1196",
      link: "/eeco-cargo",
    },
  ],
};
