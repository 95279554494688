import React, { useEffect, useState, Fragment } from "react";
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import CustomLoader from "../../components/CustomLoader";
import { Link } from "react-router-dom";
import { IoEye } from "react-icons/io5";
import { AiFillEdit } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { FaTrash } from "react-icons/fa";

const customStyles = {
  rows: {
    style: {
      minHeight: "55px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the headcells padding for head cells
      paddingRight: "8px",
      backgroundColor: "#7e22ce",
      color: "white",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      borderRight: "1px solid #eaeaea",
    },
  },
};

function AllVariant() {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let list = [];
      try {
        const collectionRef = collection(db, "prices");
        const q = query(collectionRef);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setData(list);
          setFilteredData(list);
        });
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (row) => {
    try {
      await deleteDoc(doc(db, "prices", row.id));
      toast.success("Deleted Successfully", {
        theme: "colored",
      });
      window.location.reload();
    } catch (error) {
      toast.error("Something went wrong!", {
        theme: "colored",
      });
    }
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Date",
      selector: (row) => row.timestamp,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center space-x-2">
          <Link
            to={`/admin/variant/view/${row.id}`}
            className="bg-blue-500 text-white px-3 py-1.5 rounded"
          >
            <IoEye />
          </Link>
          {/* <Link
            to={`/admin/variant/edit/${row.id}`}
            className="bg-yellow-500 text-white px-3 py-1.5 rounded"
          >
            <AiFillEdit />
          </Link> */}
          <button
            className="bg-red-600 text-white px-3 py-1.5 rounded"
            onClick={() => handleDelete(row)}
          >
            <FaTrash />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const result = data?.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(result);
  }, [search, data]);
  return (
    <>
      <div className="flex flex-row h-screen">
        <Sidebar active={active} />
        <div className="flex-auto bg-gray-50 overflow-auto">
          <Navbar handleActive={handleActive} />
          <div className="mx-5 mt-5">
            <DataTable
              title="All Variant Prices"
              columns={columns}
              data={filteredData}
              progressPending={loading}
              progressComponent={<CustomLoader />}
              selectableRows
              selectableRowsHighlight
              pagination
              fixedHeader
              fixedHeaderScrollHeight="100vh"
              customStyles={customStyles}
              highlightOnHover
              subHeader
              actions={
                <Link
                  to="/admin/add-variant"
                  className="bg-green-600 text-sm px-4 py-2 5 text-white rounded"
                >
                  Create Variant
                </Link>
              }
              subHeaderComponent={
                <SearchComponent search={search} setSearch={setSearch} />
              }
            />
          </div>
          <div className="mx-5 mt-5">
            <HomeScreenPrice />
          </div>
        </div>
      </div>
    </>
  );
}

const SearchComponent = ({ search, setSearch }) => {
  return (
    <div className="flex items-center justify-center">
      <input
        className="border-2 rounded mb-3 py-2 px-4 text-gray-700 font-light focus:outline-none"
        type="text"
        placeholder="Search by name, phone or email"
        value={search}
        style={{ width: "300px" }}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
};

export default AllVariant;

const HomeScreenPrice = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState();
  const [newPrice, setNewPrice] = useState();
  const [id, setId] = useState();
  const [editLoading, setEditLoading] = useState(false);

  async function openModal(row) {
    setIsOpen(true);
    setId(row.id);
    try {
      const docRef = doc(db, "homeVehPrices", row.id);
      const docSnap = await getDoc(docRef);
      setEdit(docSnap.data());
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleUpdate = async () => {
    setEditLoading(true);
    try {
      const docRef = doc(db, "homeVehPrices", id);
      await updateDoc(docRef, {
        price: newPrice,
      });
      toast.success("Updated Successfully", {
        theme: "colored",
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
    setEditLoading(false);
  };

  const handleDelete = async (row) => {
    try {
      await deleteDoc(doc(db, "homeVehPrices", row.id));
      toast.success("Deleted Successfully", {
        theme: "colored",
      });
      window.location.reload();
    } catch (error) {
      toast.error("Something went wrong!", {
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let list = [];
      try {
        const collectionRef = collection(db, "homeVehPrices");
        const q = query(collectionRef);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setData(list);
        });
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "price",
      selector: (row) => row.price,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center space-x-2">
          <button
            className="bg-yellow-500 text-white px-3 py-1.5 rounded"
            onClick={() => openModal(row)}
          >
            <AiFillEdit />
          </button>
          <button
            className="bg-red-600 text-white px-3 py-1.5 rounded"
            onClick={() => handleDelete(row)}
          >
            <FaTrash />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <DataTable
        title="Home Screen Variants"
        columns={columns}
        data={data}
        progressPending={loading}
        progressComponent={<CustomLoader />}
        selectableRows
        selectableRowsHighlight
        pagination
        fixedHeader
        fixedHeaderScrollHeight="100vh"
        customStyles={customStyles}
        highlightOnHover
        subHeader
        // actions={
        //   <Link
        //     to="/admin/add-variant"
        //     className="bg-green-600 text-sm px-4 py-2 5 text-white rounded"
        //   >
        //     Create Home Variant
        //   </Link>
        // }
      />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2">
                    <div className="relative w-full mb-4">
                      <label className="block font-semibold text-purple-600 text-sm mb-1">
                        Model Name:
                      </label>
                      <input
                        type="text"
                        readOnly
                        className="px-3 py-3 bg-[#f5f8fa] rounded text-sm w-full focus:border focus:border-gray-200 focus:outline-none"
                        value={edit?.name}
                      />
                    </div>

                    <div className="relative w-full mb-4">
                      <label className="block font-semibold text-purple-600 text-sm mb-1">
                        Old Price
                      </label>
                      <input
                        type="text"
                        readOnly
                        className="px-3 py-3 bg-[#f5f8fa] rounded text-sm w-full focus:border focus:border-gray-200 focus:outline-none"
                        value={edit?.price}
                      />
                    </div>

                    <div className="relative w-full mb-4">
                      <label className="block font-semibold text-purple-600 text-sm mb-1">
                        New Price
                      </label>
                      <input
                        type="text"
                        className="px-3 py-3 bg-[#f5f8fa] rounded text-sm w-full focus:border focus:border-gray-200 focus:outline-none"
                        value={newPrice}
                        onChange={(e) => setNewPrice(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                      onClick={() => handleUpdate()}
                    >
                      {editLoading ? "Updating..." : "Update"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
