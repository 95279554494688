import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import About from "../components/about/About";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import AllModels from "../components/home/AllModels";
import OurChannels from "../components/home/OurChannels";
import Slider from "../components/home/Slider";
import { sliders } from "../constants";

function Home() {
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Pavan Motors No 1 Maruti Dealer in Hyderabad, Telangana"
        />
        <meta
          name="description"
          content="Pavan Motors No 1 Maruti Dealers in Hyderabad, Telangana. Check out the best offers, awesome deals and largest selection of Maruti Cars in the authorized dealerships across Telangana."
        />
        <meta
          name="keywords"
          content="Maruti Suzuki Dealers, Maruti Authorized Dealers, Nexa Dealers"
        />
      </Helmet>
      <Header />
      <div className="relative">
        <Slider sliders={sliders} />
      </div>
      <OurChannels />
      <AllModels />
      <About />
      <ServiceOptions />
      <Footer />
    </>
  );
}

const ServiceOptions = () => {
  const opt = [
    {
      title: "To Book a test Drive?",
      link: "/book-a-service",
      btn_txt: "Book a test drive",
    },
    {
      title: "To Book a service?",
      link: "/book-a-service",
      btn_txt: "Book a service",
    },
    {
      title: "To sell/exchange your car?",
      link: "/book-a-service",
      btn_txt: "sell your car",
    },
  ];
  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 my-8 gap-5 px-5 lg:px-0">
      {opt?.map((a, index) => (
        <div
          className="border border-gray-100 bg-gray-50 p-5 hover:-translate-y-1 duration-300"
          key={index}
        >
          <p className="text-red-600">Are you looking</p>
          <p className="uppercase font-semibold text-xl mb-6">{a.title}</p>
          <Link
            to={a.link}
            className="border-2 border-red-600 text-red-600 px-5 uppercase py-2.5 "
          >
            {a.btn_txt}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Home;
