import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";
import { BsSpeedometer2 } from "react-icons/bs";
import { GiFuelTank } from "react-icons/gi";
import { TbEngine } from "react-icons/tb";
import { GrFormNext } from "react-icons/gr";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { arena } from "../../constants/allVehicles";
import { Tab } from "@headlessui/react";
import EnquiryForm from "../../components/EnquiryForm";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import Dimensions from "../../components/specs/Dimensions";
import Brakes from "../../components/specs/Brakes";
import Weight from "../../components/specs/Weight";
import Capacity from "../../components/specs/Capacity";
import Engine from "../../components/specs/Engine";

function AltoDetail() {
  const [data, setData] = useState();
  const [currentModel, setCurrentModel] = useState();
  const [selectModel, setSelectModel] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      const collectionRef = collection(db, "prices");
      try {
        const q = query(collectionRef, where("name", "==", "Alto 800"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, name: doc.name, ...doc.data() });
          setData(list);
        });
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
    for (let index = 0; index < data?.length; index++) {
      const element = data[0].prices[0].price;
      localStorage.setItem("alto", element);
    }
    setCurrentModel(localStorage.getItem("alto"));
  }, [data]);
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Offers on New Alto – Maruti Suzuki Alto 800 On Road Price in Hyderabad"
        />
        <meta
          name="description"
          content="Offers on New Alto in Hyderabad, Telangana- Visit Pavan Motors today to know about Maruti Suzuki Alto 800 On Road Price in Hyderabad, its variants, mileage, and specifications. Find the finance offers and Ex-showroom price of Maruti Alto 800 in Secundearabad."
        />
        <meta
          name="keywords"
          content="maruti alto on road price, maruti alto price,"
        />
      </Helmet>
      <Header />
      <img
        src={arena?.alto[0].banner}
        className="w-full lg:h-[550px] object-cover"
        alt={arena?.alto[0].name}
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / {arena?.alto[0].name}
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 px-5 md:px-0 container my-8 mx-auto">
        <div className="lg:col-span-2 col-span-2  ">
          <Slider />
          <p className="text-3xl font-semibold">{arena?.alto[0].name}</p>

          <Tab.Group>
            <Tab.List className="space-x-3 mt-4">
              {/* Dimension */}
              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white outline-none rounded"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Dimensions
                </p>
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white rounded outline-none"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Engine
                </p>
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white rounded outline-none"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Brakes
                </p>
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white rounded outline-none"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Weight
                </p>
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white rounded outline-none"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Capacity
                </p>
              </Tab>
            </Tab.List>
            <Tab.Panels>
              {/* specifications */}
              <Tab.Panel>
                <Dimensions specs={arena?.alto} />
              </Tab.Panel>

              <Tab.Panel>
                <Engine specs={arena?.alto} />
              </Tab.Panel>

              <Tab.Panel>
                <Brakes specs={arena?.alto} />
              </Tab.Panel>

              <Tab.Panel>
                <Weight specs={arena?.alto} />
              </Tab.Panel>

              <Tab.Panel>
                <Capacity specs={arena?.alto} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>

        {/* form */}
        <div className="col-span-1 border p-5 relative">
          <p className="text-xl font-medium">
            ₹ {selectModel !== null ? selectModel : currentModel}
            <span className="text-red-600">*</span>
          </p>
          <p className="text-purple-700 mb-3">Ex-Showroom Price</p>

          <select
            value={selectModel}
            onChange={(e) => setSelectModel(e.target.value)}
            className="w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
          >
            {data?.map((m) =>
              m.prices.map((item, i) => (
                <option value={item.price} key={i}>
                  {item.variant}
                </option>
              ))
            )}
          </select>

          <EnquiryForm />

          <div className="grid grid-cols-3 gap-4 mt-5">
            <div className="col-span-1 border shadow rounded p-3 space-y-2">
              <TbEngine size={40} className="mx-auto" />
              <p className="text-center">
                {arena?.alto[0].engine} <span className="text-xs">cc</span>
              </p>
              <p className="text-center">Engine</p>
            </div>
            <div className="col-span-1 border shadow rounded p-3 space-y-2">
              <BsSpeedometer2 size={40} className="mx-auto" />
              <p className="text-center">
                {arena?.alto[0].petrol} <span className="text-xs">km/l</span>
              </p>
              <p className="text-center">Petrol</p>
            </div>
            <div className="col-span-1 border shadow rounded p-3 space-y-2">
              <GiFuelTank size={40} className="mx-auto" />
              <p className="text-center">
                {arena?.alto[0].cng} <span className="text-xs">km/kg</span>
              </p>
              <p className="text-center">CNG</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const Slider = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let list = [];
      try {
        const collectionRef = collection(db, "vehicleImages");
        const q = query(collectionRef);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setData(list);
        });
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
  }, []);

  return (
    <div className="m-2 rounded-lg overflow-hidden">
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        loop={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper slider-home"
      >
        {data?.map((slider) => (
          <>
            {slider.category === "Alto 800" &&
              slider.images.map((item, index) => (
                <SwiperSlide key={index}>
                  <img src={item} alt="slider" className="w-full" />
                </SwiperSlide>
              ))}
          </>
        ))}

        <div
          ref={navigationPrevRef}
          className="absolute left-10 top-1/2 z-10 bg-white rounded-lg p-3 cursor-pointer"
        >
          <GrFormNext className="rotate-180" />
        </div>
        <div
          ref={navigationNextRef}
          className="absolute right-10 top-1/2 z-10 bg-white rounded-lg p-3 cursor-pointer"
        >
          <GrFormNext />
        </div>
      </Swiper>
    </div>
  );
};

export default AltoDetail;
