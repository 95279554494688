import React from "react";
import { Tab } from "@headlessui/react";
import { homeScreenCars } from "../../constants/homecars";
import { Link } from "react-router-dom";
import SliderCard from "../Card/SliderCard";

function AllModels() {
  return (
    <div className="container mx-auto my-8">
      <p className="font-semibold uppercase text-4xl ml-3">
        for your quick look
      </p>
      <div className="my-6">
        <Tab.Group>
          <div className="col-span-1">
            <Tab.List className="space-x-3">
              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white mb-3 rounded-lg outline-none max-w-[260px] w-full ml-3"
                    : "bg-gray-200 rounded-lg outline-none text-black hover:bg-red-400 hover:text-white mb-3 max-w-[260px] w-full ml-3"
                }
              >
                <p className="inline-flex py-4 px-4 text-sm font-medium text-center rounded-t-lg border-b-2 border-transparent group uppercase">
                  Arena
                </p>
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white mb-3 rounded-lg outline-none max-w-[260px] w-full"
                    : "bg-gray-200 rounded-lg outline-none text-black hover:bg-red-400 hover:text-white mb-3 max-w-[260px] w-full"
                }
              >
                <p className="inline-flex py-4 px-4 text-sm font-medium text-center group uppercase ">
                  Nexa
                </p>
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white mb-3 rounded-lg outline-none max-w-[260px] w-full"
                    : "bg-gray-200 rounded-lg outline-none text-black hover:bg-red-400 hover:text-white mb-3 max-w-[260px] w-full"
                }
              >
                <p className="inline-flex py-4 px-4 text-sm font-medium text-center group uppercase ">
                  Commercial
                </p>
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white mb-3 rounded-lg outline-none max-w-[260px] w-full"
                    : "bg-gray-200 rounded-lg outline-none text-black hover:bg-red-400 hover:text-white mb-3 max-w-[260px] w-full"
                }
              >
                <Link
                  to="/truevalue"
                  className="inline-flex py-4 px-4 text-sm font-medium text-center group uppercase "
                >
                  Truevalue
                </Link>
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white mb-3 rounded-lg outline-none max-w-[260px] w-full"
                    : "bg-gray-200 rounded-lg outline-none text-black hover:bg-red-400 hover:text-white mb-3 max-w-[260px] w-full"
                }
              >
                <Link
                  to="/driving-school-hyderabad"
                  className="inline-flex py-4 px-4 text-sm font-medium text-center group uppercase "
                >
                  Maruti Driving School
                </Link>
              </Tab>
            </Tab.List>
          </div>
          <div className="col-span-3 px-3">
            <Tab.Panels>
              {/* Arena */}
              <Tab.Panel>
                <SliderCard specs={homeScreenCars?.arena} />
              </Tab.Panel>

              {/* Nexa */}
              <Tab.Panel>
                <SliderCard specs={homeScreenCars?.nexa} />
              </Tab.Panel>

              {/* Commercial */}
              <Tab.Panel>
                <SliderCard specs={homeScreenCars?.tour} />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
}

export default AllModels;
