import { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "./context/AuthContext";
import Home from "./screens/Home";
import Dashboard from "./screens/admin/Dashboard";
import Login from "./screens/auth/Login";
import About from "./screens/About";
import Finance from "./screens/Finance";
import Contact from "./screens/Contact";
import Insurance from "./screens/Insurance";
import BookAService from "./screens/BookAService";
import DrivingSchool from "./screens/DrivingSchool";
import AltoDetail from "./screens/cars/AltoDetail";
import BrezzaDetail from "./screens/cars/BrezzaDetail";
import CelerioDetail from "./screens/cars/CelerioDetail";
import DzireDetail from "./screens/cars/DzireDetail";
import SwiftDetail from "./screens/cars/SwiftDetail";
import WagonRDetail from "./screens/cars/WagonRDetail";
import EecoDetail from "./screens/cars/EecoDetails";
import ErtigaDetail from "./screens/cars/ErtigaDetail";
import SpressoDetail from "./screens/cars/SpressoDetail";
import Arena from "./screens/channels/Arena";
import Nexa from "./screens/channels/Nexa";
import Commercial from "./screens/channels/Commercial";
import BalenoDetail from "./screens/cars/BalenoDetail";
import CiazDetail from "./screens/cars/CiazDetail";
import XLDetail from "./screens/cars/XlDetail";
import IgnisDetail from "./screens/cars/IgnisDetail";
import ScrossDetail from "./screens/cars/ScrossDetail";
import TourH1 from "./screens/cars/TourH1";
import TourH3 from "./screens/cars/TourH3";
import TourM from "./screens/cars/TourM";
import NotFound from "./screens/NotFound";
import TourS from "./screens/cars/TourS";
import TourV from "./screens/cars/TourV";
import Leads from "./screens/admin/Leads";
import Thankyou from "./screens/Thankyou";
import FinanceLeads from "./screens/admin/FinanceLeads";
import AltoK10Detail from "./screens/cars/AltoK10Detail";
import UploadImages from "./screens/admin/UploadImages";
import AllImages from "./screens/admin/AllImages";
import CarryDetail from "./screens/cars/goods/CarryDetail";
import CargoDetail from "./screens/cars/goods/CargoDetail";
import AllVehicles from "./screens/admin/AllVehicles";
import ViewVehicle from "./screens/admin/ViewVehicle";
import GrandVitaraDetail from "./screens/cars/GrandVitaraDetail";
import CreateSlider from "./screens/admin/CreateSlider";
import CreateVariant from "./screens/admin/CreateVariant";
import EditVariant from "./screens/admin/EditVariant";
import ViewVariant from "./screens/admin/ViewVariant";
import AllVariant from "./screens/admin/AllVariant";
import Truevalue from "./screens/Truevalue";
import AllTruevalue from "./screens/admin/AllTruevalue";
import JimnyDetail from "./screens/cars/JimnyDetail";
import FronxDetail from "./screens/cars/FronxDetail";
import BalenoCng from "./screens/cng/BalenoCng";
import Xlcng from "./screens/cng/Xlcng";
import VitaraCng from "./screens/cng/VitaraCng";
import InvictoDetail from "./screens/cars/InvictoDetail";
import FronxCng from "./screens/cng/FronxCng";

function App() {
  const { currentUser } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    return currentUser ? children : <Navigate to="/" />;
  };

  const ScrollTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  return (
    <>
      <ToastContainer />
      <ScrollTop />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/book-a-service" element={<BookAService />} />
        <Route path="/truevalue" element={<Truevalue />} />
        <Route path="/driving-school-hyderabad" element={<DrivingSchool />} />

        {/* ===========Arena=========== */}
        <Route path="/maruti-suzuki-alto-price" element={<AltoDetail />} />
        <Route
          path="/maruti-suzuki-alto-k10-price"
          element={<AltoK10Detail />}
        />
        <Route path="/maruti-suzuki-brezza-price" element={<BrezzaDetail />} />
        <Route
          path="/maruti-suzuki-celerio-price"
          element={<CelerioDetail />}
        />
        <Route
          path="/maruti-suzuki-s-presso-price"
          element={<SpressoDetail />}
        />
        <Route path="/maruti-suzuki-ertiga-price" element={<ErtigaDetail />} />
        <Route path="/maruti-suzuki-eeco-price" element={<EecoDetail />} />
        <Route path="/maruti-suzuki-dzire-price" element={<DzireDetail />} />
        <Route path="/maruti-suzuki-swift-price" element={<SwiftDetail />} />
        <Route path="/maruti-suzuki-wagonR-price" element={<WagonRDetail />} />

        {/* ===========Categories=========== */}
        <Route path="/arena" element={<Arena />} />
        <Route path="/nexa" element={<Nexa />} />
        <Route path="/commercial" element={<Commercial />} />

        {/* ===========Nexa=========== */}
        <Route path="/baleno" element={<BalenoDetail />} />
        <Route path="/ciaz" element={<CiazDetail />} />
        <Route path="/scross" element={<ScrossDetail />} />
        <Route path="/ignis" element={<IgnisDetail />} />
        <Route path="/xl6" element={<XLDetail />} />
        <Route path="/grandvitara" element={<GrandVitaraDetail />} />
        <Route path="/jimny" element={<JimnyDetail />} />
        <Route path="/fronx" element={<FronxDetail />} />
        <Route path="/invicto" element={<InvictoDetail />} />

        {/* ===========CNG=========== */}
        <Route path="/baleno-cng" element={<BalenoCng />} />
        <Route path="/fronx-cng" element={<FronxCng />} />
        <Route path="/xl6-cng" element={<Xlcng />} />
        <Route path="/grandvitara-cng" element={<VitaraCng />} />

        {/* ===========Commercial=========== */}
        <Route path="/tourh1" element={<TourH1 />} />
        <Route path="/tourh3" element={<TourH3 />} />
        <Route path="/tourm" element={<TourM />} />
        <Route path="/tours" element={<TourS />} />
        <Route path="/tourv" element={<TourV />} />
        <Route path="/super-carry" element={<CarryDetail />} />
        <Route path="/eeco-cargo" element={<CargoDetail />} />

        <Route path="*" element={<NotFound />} />

        {/* ===========Admin authentication=========== */}
        <Route path="/login" element={<Login />} />

        {/* ===========admin routes=========== */}
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/upload-images"
          element={
            <ProtectedRoute>
              <UploadImages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/all-images"
          element={
            <ProtectedRoute>
              <AllImages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/leads"
          element={
            <ProtectedRoute>
              <Leads />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/finance"
          element={
            <ProtectedRoute>
              <FinanceLeads />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/vehicles"
          element={
            <ProtectedRoute>
              <AllVehicles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/vehicle/:id"
          element={
            <ProtectedRoute>
              <ViewVehicle />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/banners"
          element={
            <ProtectedRoute>
              <CreateSlider />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/truevalue"
          element={
            <ProtectedRoute>
              <AllTruevalue />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/prices"
          element={
            <ProtectedRoute>
              <AllVariant />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/add-variant"
          element={
            <ProtectedRoute>
              <CreateVariant />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/variant/view/:id"
          element={
            <ProtectedRoute>
              <ViewVariant />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/variant/edit/:id"
          element={
            <ProtectedRoute>
              <EditVariant />
            </ProtectedRoute>
          }
        />
        <Route paht="*" element={<Navigate to="/" />} />
        <Route path="/thank-you" element={<Thankyou />} />
      </Routes>
    </>
  );
}

export default App;
