import React, { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import { Helmet } from "react-helmet";

function BookAService() {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [model, setModel] = useState("");
  const [pickup, setPickup] = useState("");
  const [loading, setLoading] = useState(false);

  const pattern = /^[6-9][0-9]{6,9}$/;
  if (phone !== "" && phone.length === 10 && !pattern.test(phone)) {
    toast.error("Enter valid phone number", {
      theme: "colored",
    });
  }

  let navigate = useNavigate();

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (name !== "" && phone !== "" && model !== "") {
      const dataCollectionRef = collection(db, "enquiries");
      try {
        await addDoc(dataCollectionRef, {
          name: name,
          email: email,
          phone: phone,
          model: model,
          pickup: pickup,
          timestamp: dateTime,
        });
        navigate("/thank-you");
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Online Booking: Maruti Suzuki Car Service in Hyderabad | Maruti Suzuki Car repair in Hyderabad"
        />
        <meta
          name="description"
          content="Get your car serviced at authorized Maruti Suzuki Service Centers in Hyderabad. Book online and avail the best Maruti Suzuki service in Hyderabad. We provide express body repair, paintless dent removal, 3D wheel alignment, Auto Car wash, pollution check, on road services, and free pick-up and drop in Bangalore. We are open seven days a week."
        />
        <meta
          name="keywords"
          content="Maruti Suzuki Service Center, Maruti Car Service, Maruti Service Center Near Me, Maruti Service Centers in Hyderabad, Maruti Authorized Service Center Near Me"
        />
      </Helmet>
      <Header />
      <img
        src={require("../assets/Book-Service.png")}
        className="w-full lg:h-[550px] object-cover"
        alt="inusrance banner"
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / Book a Service
        </p>
      </div>
      <div className="container mx-auto py-16 px-5">
        <p className="text-red-500 font-semibold text-2xl">
          Maruti Car Service
        </p>
        <form onSubmit={handleSubmit} className="mt-5 space-y-3">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-3">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full focus:ring-purple-500 focus:border-purple-500"
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full focus:ring-purple-500 focus:border-purple-500"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone <span className="text-red-500">*</span>
              </label>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full focus:ring-purple-500 focus:border-purple-500"
                type="text"
                maxLength="10"
                minLength="10"
                required
                value={phone}
                onChange={(e) =>
                  setPhone(
                    e.target.value.replace(/[^1-9 ]/g, "") &&
                      e.target.value.replace(/ /g, "")
                  )
                }
              />
              {!pattern.test(phone) && phone.length === 10 ? (
                <small className="text-red-500">phone number is invalid</small>
              ) : (
                ""
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Model <span className="text-red-500">*</span>
              </label>
              <select
                required
                onChange={(e) => setModel(e.target.value)}
                className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
              >
                <option>Select Model</option>
                <optgroup label="Arena">
                  <option value="Alto 800">Maruti Alto</option>
                  <option value="Alto K10">Maruti Alto K10</option>
                  <option value="Wagon R">Maruti Wagon R</option>
                  <option value="Celerio">Maruti New Celerio</option>
                  <option value="Swift">Maruti Swift</option>
                  <option value="Dzire">Maruti Dzire</option>
                  <option value="S-Presso">Maruti S-Presso</option>
                  <option value="Ertiga">Maruti Ertiga</option>
                  <option value="Brezza">Maruti New Brezza</option>
                  <option value="Eeco">Maruti Eeco</option>
                </optgroup>
                <optgroup label="Nexa">
                  <option value="Ciaz">Maruti Ciaz</option>
                  <option value="XL6">Maruti XL6</option>
                  <option value="Baleno">Maruti Baleno</option>
                  <option value="Ignis">Maruti Ignis</option>
                  <option value="Grand Vitara">Maruti Grand Vitara</option>
                </optgroup>
                <optgroup label="Passenger Carrier">
                  <option value="Tour H1">Maruti Tour H1</option>
                  <option value="Tour H3">Maruti Tour H3</option>
                  <option value="Tour S">Maruti Tour S</option>
                  <option value="Tour V">Maruti Tour V</option>
                  <option value="Tour M">Maruti Tour M</option>
                </optgroup>
                <optgroup label="Goods Carrier">
                  <option value="Super Carry">Super Carry</option>
                  <option value="Eeco Cargo">Eeco Cargo</option>
                </optgroup>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Pickup
              </label>
              <select
                required
                onChange={(e) => setPickup(e.target.value)}
                className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
              >
                <option>Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <p className="text-gray-700">
            <span className="text-black font-bold">Disclaimer</span>: I agree
            that by clicking the ‘Submit’ button below, I am explicitly
            soliciting a call / Message from Pavan Motor Pvt. Ltd or its
            Representatives on my ‘Mobile’
          </p>
          <div className="flex items-start">
            <input
              id="disclaimer"
              name="disclaimer"
              type="checkbox"
              className="h-4 w-4 rounded"
            />
            <div className="ml-2 text-sm">
              <label htmlFor="disclaimer" className="font-medium text-gray-700">
                Please Agree with Following Disclaimer
              </label>
            </div>
          </div>
          <button
            className="bg-purple-800 hover:bg-red-500 duration-500 text-white rounded py-2.5 px-5"
            type="submit"
            disabled={pattern.test(phone) && phone.length === 10 ? false : true}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <CgSpinner className="animate-spin h-5 mr-2 text-white w-5" />
                Loading
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default BookAService;
