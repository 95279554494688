import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { FreeMode, Autoplay, Navigation } from "swiper";
import Car from "../home/Car";
import { useEffect, useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";

function SliderCard({ specs }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let list = [];
      try {
        const collectionRef = collection(db, "homeVehPrices");
        const q = query(collectionRef);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setData(list);
        });
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
  }, []);
  return (
    <Swiper
      slidesPerView={1}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      }}
      spaceBetween={20}
      freeMode={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[FreeMode, Navigation, Autoplay]}
      className="mySwiper"
    >
      {specs.map((item, i) => (
        <SwiperSlide key={i}>
          <Car
            link={item.link}
            img={item.img}
            name={item.name}
            price={data?.map((m) => (m.name === item.nick ? m.price : ""))}
            kmpl={item.kmpl}
            engine={item.engine}
            seats={item.seats}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default SliderCard;
