import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import { db } from "../../firebase";
import { HiTrash } from "react-icons/hi";

function ViewVehicle() {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState();
  const [images, setImages] = useState();
  const storage = getStorage();

  useEffect(() => {
    const getData = async () => {
      try {
        const docRef = doc(db, "vehicleImages", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setData(docSnap.data());
          setImages(docSnap.data().images);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [id]);

  const handleDelete = async (item) => {
    setImages(images.filter((e) => e !== item));
    const img = item.split("/")[7].split("%")[1].split("?")[0].split("F")[1];
    const desertRef = ref(storage, `vehicles/${img}`);
    try {
      await deleteObject(desertRef);
      toast.success("Deleted Successfully", {
        theme: "colored",
      });
    } catch (error) {
      toast.error("Something went wrong", {
        theme: "colored",
      });
    }
  };

  const updateItem = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "vehicleImages", id);
      await updateDoc(docRef, {
        images: images,
      });
      toast.success("Updated Successfully", {
        theme: "colored",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteItem = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "vehicleImages", id);
      for (let index = 0; index < data.images.length; index++) {
        const element = data.images[index];
        const img = element
          .split("/")[7]
          .split("%")[1]
          .split("?")[0]
          .split("F")[1];
        const desertRef = ref(storage, `vehicles/${img}`);
        try {
          await deleteObject(desertRef);
        } catch (error) {
          toast.error("Something went wrong", {
            theme: "colored",
          });
        }
      }
      await deleteDoc(docRef);
      navigate("/admin/vehicles");
      toast.success("Deleted Successfully!", {
        theme: "colored",
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="flex flex-row h-screen">
        <Sidebar active={active} />
        <div className="flex-auto bg-gray-50 overflow-auto">
          <Navbar handleActive={handleActive} />
          <div className="bg-white m-10 p-5">
            <p className="text-3xl font-semibold">{data?.category}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-10">
              {images?.map((item) => (
                <div className="relative" key={item}>
                  <img src={item} alt="" />
                  <button
                    className="absolute top-0 right-0 bg-red-500 text-white p-3 rounded-bl-lg"
                    onClick={() => handleDelete(item)}
                  >
                    <HiTrash />
                  </button>
                </div>
              ))}
            </div>
            {images && (
              <button
                className="px-3 py-2.5 bg-green-600 text-white mt-10 rounded"
                onClick={(e) => updateItem(e)}
              >
                Update
              </button>
            )}
            <button
              className="px-3 py-2.5 bg-red-600 text-white mt-10 rounded ml-3"
              onClick={(e) => deleteItem(e)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewVehicle;
