import React, { useEffect, useState } from "react";
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import DataTable from "react-data-table-component";
import { IoEye } from "react-icons/io5";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";

const customStyles = {
  rows: {
    style: {
      minHeight: "55px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      backgroundColor: "#7e22ce",
      color: "white",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      borderRight: "1px solid #eaeaea",
    },
  },
};

function AllVehicles() {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };

  const [data, setData] = useState();

  const [loading, setLoading] = useState(true);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.category,
    },
    {
      name: "Images",
      selector: (row) => (
        <div className="grid grid-cols-3 gap-5">
          <img src={row.images[0]} alt="1" className="h-20" />
          <img src={row.images[1]} alt="2" className="h-20" />
          <img src={row.images[2]} alt="3" className="h-20" />
          {/* {row.images.map((item) => (
              <img src={item} key={item} alt="1" className="h-20" />
            ))} */}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center space-x-2">
          <Link
            to={`/admin/vehicle/${row.id}`}
            className="bg-blue-500 text-white px-3 py-1.5 rounded"
          >
            <IoEye />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let list = [];
      try {
        const collectionRef = collection(db, "vehicleImages");
        const q = query(collectionRef);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setData(list);
        });
      } catch (error) {
        toast.error("Something went wrong!");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-row h-screen">
        <Sidebar active={active} />
        <div className="flex-auto bg-gray-50 overflow-auto">
          <Navbar handleActive={handleActive} />
          <div className="mx-5 mt-5">
            <DataTable
              title="All Vehicles"
              columns={columns}
              data={data}
              progressPending={loading}
              progressComponent={<CustomLoader />}
              selectableRows
              selectableRowsHighlight
              pagination
              fixedHeader
              fixedHeaderScrollHeight="100vh"
              customStyles={customStyles}
              highlightOnHover
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllVehicles;
