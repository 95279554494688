import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { GrFormNext } from "react-icons/gr";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper";

function Slider({ sliders }) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  return (
    <div className="m-2 rounded-lg overflow-hidden">
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        loop={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper slider-home"
      >
        {sliders.map((item, i) => (
          <SwiperSlide key={i}>
            <img
              src={item.img}
              loading="lazy"
              className="max-w-full w-full object-cover"
              alt={i}
            />
          </SwiperSlide>
        ))}

        <div
          ref={navigationPrevRef}
          className="absolute left-3 sm:left-10 top-1/2 sm:top-1/2 z-10 bg-white rounded-lg p-1 sm:p-3 cursor-pointer"
        >
          <GrFormNext className="rotate-180" />
        </div>
        <div
          ref={navigationNextRef}
          className="absolute right-3 sm:right-10 top-1/2 sm:top-1/2 z-10 bg-white rounded-lg p-1 sm:p-3 cursor-pointer"
        >
          <GrFormNext />
        </div>
      </Swiper>
    </div>
  );
}

export default Slider;
