import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Car from "../../components/home/Car";
import { homeScreenCars } from "../../constants/homecars";
import { collection, getDocs, query } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { Helmet } from "react-helmet";

function Nexa() {
  const [data, setData] = useState();
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    // setLoading(true);
    const fetchData = async () => {
      let list = [];
      try {
        const collectionRef = collection(db, "homeVehPrices");
        const q = query(collectionRef);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setData(list);
        });
        // setLoading(false);
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Best Nexa Car Dealers in Hyderabad | Pavan Motors"
        />
        <meta
          name="description"
          content="Pavan Motors one of the best authorized Maruti Suzuki Nexa dealer in Hyderabad, Telangana. Visit nearest Pavan Motors Nexa showroom today to get best offers on Baleno, Ignis, Ciaz, Xl6 and Grand Vitara."
        />
      </Helmet>
      <Header />
      <img
        src={require("../../assets/banners/grand_vitara.webp")}
        className="w-full"
        alt="nexa"
      />
      <p className="text-center mt-16 font-semibold text-xl lg:text-3xl">
        Nexa Cars
      </p>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 px-5 mt-8 mb-16">
        {homeScreenCars?.nexa.map((item, i) => (
          <Car
            key={i}
            link={item.link}
            img={item.img}
            name={item.name}
            price={data?.map((m) => (m.name === item.nick ? m.price : ""))}
            kmpl={item.kmpl}
            engine={item.engine}
            seats={item.seats}
          />
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Nexa;
