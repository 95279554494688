import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsSpeedometer2 } from "react-icons/bs";
import { GiFuelTank } from "react-icons/gi";
import { TbEngine } from "react-icons/tb";
import { GrFormNext } from "react-icons/gr";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { nexa } from "../../constants/allVehicles";
import { Tab } from "@headlessui/react";
import EnquiryForm from "../../components/EnquiryForm";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

function XLDetail() {
  const [data, setData] = useState();
  const [currentModel, setCurrentModel] = useState(null);
  const [selectModel, setSelectModel] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      const collectionRef = collection(db, "prices");
      try {
        const q = query(collectionRef, where("name", "==", "XL6"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, name: doc.name, ...doc.data() });
          setData(list);
        });
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
    let element;
    for (let index = 0; index < data?.length; index++) {
      element = data[0].prices[0].price;
      localStorage.setItem("xl6", element);
    }
    setCurrentModel(localStorage.getItem("xl6"));
  }, [data]);
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="NEXA Xl6 Price in Hyderabad | Maruti Xl6 Price | Pavan Nexa"
        />
        <meta
          name="description"
          content="Check out Maruti Xl6 On road price in Hyderabad. NEXA Xl6 Price in Telangana. Get Xl6 Specifications, Features, Mileage, Insurance, Finance, Colors & Variants Details from Pavan Motors. Call now for more offers."
        />
        <meta
          name="keywords"
          content="XL6 on road price, XL6 mileage, XL6 offers & disconuts"
        />
      </Helmet>
      <Header />
      <img
        src={nexa?.xl6[0].banner}
        className="w-full lg:h-[550px] object-cover"
        alt={nexa?.xl6[0].name}
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / {nexa?.xl6[0].name}
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 px-5 md:px-0 container my-8 mx-auto">
        <div className="lg:col-span-2 col-span-2  ">
          <Slider />
          <div className="flex items-center justify-between">
            <p className="text-3xl font-semibold">{nexa?.xl6[0].name}</p>
            <Link
              to="/xl6-cng"
              className="bg-lime-600 text-white px-4 py-1.5 rounded"
            >
              CNG
            </Link>
          </div>

          <Tab.Group>
            <Tab.List className="space-x-3 mt-4">
              {/* Dimension */}
              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white outline-none rounded"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Dimensions
                </p>
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white rounded outline-none"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Engine
                </p>
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white rounded outline-none"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Brakes
                </p>
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white rounded outline-none"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Weight
                </p>
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? "bg-red-600 text-white rounded outline-none"
                    : "bg-gray-200 outline-none text-black hover:bg-red-400 hover:text-white rounded"
                }
              >
                <p className="inline-flex p-2 text-sm text-center border-transparent group">
                  Capacity
                </p>
              </Tab>
            </Tab.List>
            <Tab.Panels>
              {/* specifications */}
              <Tab.Panel>
                <Dimensions />
              </Tab.Panel>

              <Tab.Panel>
                <Engine />
              </Tab.Panel>

              <Tab.Panel>
                <Brakes />
              </Tab.Panel>

              <Tab.Panel>
                <Weight />
              </Tab.Panel>

              <Tab.Panel>
                <Capacity />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>

        {/* form */}
        <div className="col-span-1 border p-5 relative">
          <p className="text-xl font-medium">
            ₹ {selectModel !== null ? selectModel : currentModel}
            <span className="text-red-600">*</span>
          </p>
          <p className="text-purple-700 mb-3">Ex-Showroom Price</p>

          <select
            value={selectModel}
            onChange={(e) => setSelectModel(e.target.value)}
            className="w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
          >
            {data?.map((m) =>
              m.prices.map((item, i) => (
                <option value={item.price} key={i}>
                  {item.variant}
                </option>
              ))
            )}
          </select>

          <EnquiryForm />

          <div className="grid grid-cols-3 gap-4 mt-5">
            <div className="col-span-1 border shadow rounded p-3 space-y-2">
              <TbEngine size={40} className="mx-auto" />
              <p className="text-center">
                {nexa?.xl6[0].engine} <span className="text-xs">cc</span>
              </p>
              <p className="text-center">Engine</p>
            </div>
            <div className="col-span-1 border shadow rounded p-3 space-y-2">
              <BsSpeedometer2 size={40} className="mx-auto" />
              <p className="text-center">
                {nexa?.xl6[0].petrol} <span className="text-xs">km/l</span>
              </p>
              <p className="text-center">Petrol</p>
            </div>
            <div className="col-span-1 border shadow rounded p-3 space-y-2">
              <GiFuelTank size={40} className="mx-auto" />
              <p className="text-center">
                {nexa?.xl6[0].cng} <span className="text-xs">km/kg</span>
              </p>
              <p className="text-center">CNG</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const Slider = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let list = [];
      try {
        const collectionRef = collection(db, "vehicleImages");
        const q = query(collectionRef);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
          setData(list);
        });
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
  }, []);
  return (
    <div className="m-2 rounded-lg overflow-hidden">
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        loop={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper slider-home"
      >
        {data?.map((slider) => (
          <>
            {slider.category === "XL6"
              ? slider.images.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img src={item} alt="slider" className="w-full" />
                  </SwiperSlide>
                ))
              : ""}
          </>
        ))}

        <div
          ref={navigationPrevRef}
          className="absolute left-10 top-1/2 z-10 bg-white rounded-lg p-3 cursor-pointer"
        >
          <GrFormNext className="rotate-180" />
        </div>
        <div
          ref={navigationNextRef}
          className="absolute right-10 top-1/2 z-10 bg-white rounded-lg p-3 cursor-pointer"
        >
          <GrFormNext />
        </div>
      </Swiper>
    </div>
  );
};

const Dimensions = () => {
  return (
    <>
      {/* Dimensions */}
      <table className="border-collapse border border-slate-500 w-full mt-5">
        <tbody>
          {nexa?.xl6.map((data) =>
            data.specifications.map((feature) =>
              feature.dimensions.map((item, i) => (
                <tr key={i}>
                  <td className="border px-5 py-2.5 font-medium">
                    {item.title}
                  </td>
                  <td className="border px-5 py-2.5 font-light">
                    {item.value}
                  </td>
                </tr>
              ))
            )
          )}
        </tbody>
      </table>
    </>
  );
};

const Engine = () => (
  <table className="border-collapse border border-slate-500 w-full mt-5">
    <tbody>
      {nexa?.xl6.map((data) =>
        data.specifications.map((feature) =>
          feature.engine.map((item, i) => (
            <tr key={i}>
              <td className="border px-5 py-2.5 font-medium">{item.title}</td>
              <td className="border px-5 py-2.5 font-light">{item.value}</td>
            </tr>
          ))
        )
      )}
    </tbody>
  </table>
);

const Brakes = () => (
  <table className="border-collapse border border-slate-500 w-full mt-5">
    <tbody>
      {nexa?.xl6.map((data) =>
        data.specifications.map((feature) =>
          feature.brakes.map((item, i) => (
            <tr key={i}>
              <td className="border px-5 py-2.5 font-medium">{item.title}</td>
              <td className="border px-5 py-2.5 font-light capitalize">
                {item.value}
              </td>
            </tr>
          ))
        )
      )}
    </tbody>
  </table>
);

const Capacity = () => (
  <table className="border-collapse border border-slate-500 w-full mt-5">
    <tbody>
      {nexa?.xl6.map((data) =>
        data.specifications.map((feature) =>
          feature.capacity.map((item, i) => (
            <tr key={i}>
              <td className="border px-5 py-2.5 font-medium">{item.title}</td>
              <td className="border px-5 py-2.5 font-light capitalize">
                {item.value !== "" ? item.value : "N/A"}
              </td>
            </tr>
          ))
        )
      )}
    </tbody>
  </table>
);

const Weight = () => {
  return (
    <table className="border-collapse border border-slate-500 w-full mt-5">
      <tbody>
        {nexa?.xl6.map((data) =>
          data.specifications.map((feature) =>
            feature.weight.map((item, i) => (
              <tr key={i}>
                <td className="border px-5 py-2.5 font-medium">{item.title}</td>
                <td className="border px-5 py-2.5 font-light">{item.value}</td>
              </tr>
            ))
          )
        )}
      </tbody>
    </table>
  );
};

export default XLDetail;
