export const arena = {
  alto: [
    {
      name: "Maruti Alto",
      banner: require("../assets/banners/alto.jpeg"),
      price: "3.39",
      engine: "796",
      petrol: "22.05",
      cng: "31.59 ",
      power: "47.05",
      variants: [
        {
          name: "Alto STD(O)",
          price: "3,39,000",
        },
        {
          name: "Alto LXI(O)",
          price: "4,08,000",
        },
        {
          name: "Alto VXI",
          price: "4,28,000",
        },
        {
          name: "Alto VXI+",
          price: "4,41,500",
        },
        {
          name: "Alto LXI(O) CNG",
          price: "5,03,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Overall Length",
              value: "3445 mm",
            },
            {
              title: "Overall Width",
              value: "1490 mm (Without side molding)",
            },
            {
              title: "Overall Width",
              value: "1515 mm (With side molding)",
            },
            {
              title: "Overall Height(Unladen)",
              value: "1475 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1185 kg",
            },
            {
              title: "Kerb Weight",
              value: "762 kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "796 cc",
            },
            {
              title: "Engine Type",
              value: "F8D",
            },
            {
              title: "Max Power",
              value: "35.3 kW @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 Speed MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "3-Link Rigid Axle Suspension",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "45 L",
            },
            {
              title: "Boot Space",
              value: "177 L",
            },
          ],
        },
      ],
    },
  ],

  altok10: [
    {
      name: "Maruti Alto K10",
      banner: require("../assets/banners/Altok10_banner.webp"),
      price: "3.99",
      engine: "998",
      petrol: "24.39",
      cng: "-",
      power: "49 @ 5500",

      variants: [
        {
          name: "ALTO K10 STD",
          price: "3,99,000",
        },
        {
          name: "ALTO K10 Lxi",
          price: "4,82,000",
        },
        {
          name: "Alto K10 VXI",
          price: "4,99,500",
        },
        {
          name: "Alto K10 VXI+",
          price: "5,33,500",
        },
        {
          name: "Alto VXI AGS",
          price: "5,49,500",
        },
        {
          name: "Alto VXI+ AGS",
          price: "5,83,500",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Overall Length",
              value: "3530 mm",
            },
            {
              title: "Overall Width",
              value: "1490 mm",
            },
            {
              title: "Overall Height(Unladen)",
              value: "1520 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "- kg",
            },
            {
              title: "Kerb Weight",
              value: "- kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "998 cc",
            },
            {
              title: "Engine Type",
              value: "K10C",
            },
            {
              title: "Max Power",
              value: "49 @ 5500 [66.62 PS @ 5500 rpm]",
            },
            {
              title: "Max Torque",
              value: "89 @ 3500",
            },
            {
              title: "Transmission",
              value: "5 Speed MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut with coil spring",
            },
            {
              title: "Rear",
              value: "Torsion Beam with coil spring",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "27 L",
            },
            {
              title: "Boot Space",
              value: "214 L",
            },
          ],
        },
      ],
    },
  ],

  brezza: [
    {
      name: "Maruti Brezza",
      banner: require("../assets/banners/brezza.jpeg"),
      price: "7.99",
      engine: "1462",
      petrol: "17.05",
      cng: "-",
      power: "101.6",
      variants: [
        {
          name: "New Brezza K15C ISG 5MT LXI",
          price: "7,99,000",
        },
        {
          name: "New Brezza K15C ISG 5MT VXI",
          price: "9,46,500",
        },
        {
          name: "New Brezza K15C ISG 5MT ZXI",
          price: "10,86,500",
        },
        {
          name: "New Brezza K15C ISG 6AT VXI",
          price: "10,96,500",
        },
        {
          name: "New Brezza K15C ISG 5MT ZXI+",
          price: "12,30,000",
        },
        {
          name: "New Brezza K15C ISG 6AT ZXI",
          price: "12,36,500",
        },
        {
          name: "New Brezza K15C ISG 6AT ZXI+",
          price: "13,80,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Overall Length",
              value: "3995 mm",
            },
            {
              title: "Overall Width(Without side molding)",
              value: "-",
            },
            {
              title: "Overall Width(With side molding)",
              value: "1790 mm ",
            },
            {
              title: "Overall Height(Unladen)",
              value: "1685 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1600 kg",
            },
            {
              title: "Kerb Weight",
              value: "1110 -1130",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1462 cc",
            },
            {
              title: "Engine Type",
              value: "K15B",
            },
            {
              title: "Max Power",
              value: "75.8 kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 Speed MT / 6 AT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut & Coll Spring",
            },
            {
              title: "Rear",
              value: "Torsion Beam & Coll Spring",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "48 L",
            },
            {
              title: "Boot Space",
              value: "328 L",
            },
          ],
        },
      ],
    },
  ],

  dzire: [
    {
      name: "Maruti Dzire",
      banner: require("../assets/banners/dzire.jpeg"),
      price: "6.24",
      engine: "1197",
      petrol: "23.26",
      cng: "31.12",
      power: "88.5",

      variants: [
        {
          name: "Dzire LXI",
          price: "6,24,000",
        },
        {
          name: "Dzire VXI",
          price: "7,28,000",
        },
        {
          name: "Dzire VXI AGS",
          price: "7,78,000",
        },
        {
          name: "Dzire ZXI",
          price: "7,96,000",
        },
        {
          name: "Dzire VXI CNG",
          price: "8,23,000",
        },
        {
          name: "Dzire ZXI AGS",
          price: "8,46,000",
        },
        {
          name: "Dzire ZXI+",
          price: "8,67,500",
        },
        {
          name: "Dzire ZXI CNG",
          price: "8,91,000",
        },
        {
          name: "Dzire ZXI+ AGS",
          price: "9,17,500",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Overall Length",
              value: "3995 mm",
            },
            {
              title: "Overall Width",
              value: "1735 mm ",
            },
            {
              title: "Overall Height(Unladen)",
              value: "1515 mm",
            },
          ],
          weight: [
            {
              title: "Gross Weight",
              value: "1335 kg",
            },
            {
              title: "Kerb Weight",
              value: "880 - 915",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1197 cc",
            },
            {
              title: "Engine Type",
              value: "-",
            },
            {
              title: "Max Power",
              value: "66.0 kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 AMT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "45 L",
            },
            {
              title: "Boot Space",
              value: "378 L",
            },
          ],
        },
      ],
    },
  ],

  wagonr: [
    {
      name: "Maruti Wagon R",
      banner: require("../assets/banners/wagonr.jpeg"),
      price: "5.47",
      engine: "1197",
      petrol: "23.56",
      cng: "34.05",
      power: "88.5",
      variants: [
        {
          name: "WagonR LXI 1.0L",
          price: "5,47,500",
        },
        {
          name: "WagonR VXI 1.0L",
          price: "5,91,000",
        },
        {
          name: "WagonR ZXI 1.2L",
          price: "6,09,600",
        },
        {
          name: "WagonR VXI AGS 1.0L",
          price: "6,41,000",
        },
        {
          name: "WagonR LXI CNG 1.0L",
          price: "6,42,500",
        },
        {
          name: "WagonR ZXI+ 1.2L",
          price: "6,58,000",
        },
        {
          name: "WagonR ZXI AGS 1.2L",
          price: "6,59,600",
        },
        {
          name: "WagonR VXI CNG 1.0L",
          price: "6,86,000",
        },
        {
          name: "WagonR ZXI+ AGS 1.2L",
          price: "7,08,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Overall Length",
              value: "3655 mm",
            },
            {
              title: "Overall Width",
              value: "1620 mm ",
            },
            {
              title: "Overall Height(Unladen)",
              value: "1675 mm",
            },
          ],
          weight: [
            {
              title: "Gross Weight",
              value: "1340 kg",
            },
            {
              title: "Kerb Weight",
              value: "835 - 850",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1197 cc",
            },
            {
              title: "Engine Type",
              value: "K12N",
            },
            {
              title: "Max Power",
              value: "66.0 kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 AMT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "32 L",
            },
            {
              title: "Boot Space",
              value: "341 L",
            },
          ],
        },
      ],
    },
  ],

  celerio: [
    {
      name: "Maruti Celerio",
      banner: require("../assets/banners/celerio.jpeg"),
      price: "5.25",
      engine: "998",
      petrol: "24.97",
      cng: "35.60",
      power: "65.71",
      variants: [
        {
          name: "Celerio LXI 1L ISS 5MT",
          price: "5,25,000",
        },
        {
          name: "Celerio VXI 1L ISS 5MT",
          price: "5,74,000",
        },
        {
          name: "Celerio ZXI 1L ISS 5MT",
          price: "5,94,000",
        },
        {
          name: "Celerio VXI 1L ISS AGS",
          price: "6,24,000",
        },
        {
          name: "Celerio ZXI 1L ISS AGS",
          price: "6,44,000",
        },
        {
          name: "Celerio ZXI+ 1L ISS 5MT",
          price: "6,50,000",
        },
        {
          name: "Celerio VXI CNG 1L 5MT",
          price: "6,69,000",
        },
        {
          name: "Celerio ZXI+ 1L ISS AGS",
          price: "7,00,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Overall Length",
              value: "3695 mm",
            },
            {
              title: "Overall Width",
              value: "1655 mm ",
            },
            {
              title: "Overall Height",
              value: "1555 mm",
            },
          ],
          weight: [
            {
              title: "Gross Weight",
              value: "1260 kg",
            },
            {
              title: "Kerb Weight",
              value: "820",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "998 cc",
            },
            {
              title: "Engine Type",
              value: "K10C",
            },
            {
              title: "Max Power",
              value: "49.0 kw @ 5500 rpm",
            },
            {
              title: "Transmission",
              value: "5MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut with coil spring",
            },
            {
              title: "Rear",
              value: "Torsion Beam with coil spring",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "32 L",
            },
            {
              title: "Boot Space",
              value: "313 L",
            },
          ],
        },
      ],
    },
  ],

  swift: [
    {
      name: "Maruti Swift",
      banner: require("../assets/banners/swift.webp"),
      price: "5.25",
      engine: "1197",
      petrol: "23.76",
      cng: "-",
      power: "88.5",
      variants: [
        {
          name: "Swift LXI",
          price: "5,91,900",
        },
        {
          name: "Swift VXI",
          price: "6,82,000",
        },
        {
          name: "Swift VXI AMT",
          price: "7,32,000",
        },
        {
          name: "Swift ZXI",
          price: "7,50,000",
        },
        {
          name: "Swift ZXI AMT",
          price: "8,00,000",
        },
        {
          name: "Swift ZXI+",
          price: "8,21,000",
        },
        {
          name: "Swift ZXI+ AMT",
          price: "8,71,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Overall Length",
              value: "3845 mm",
            },
            {
              title: "Overall Width",
              value: "1735 mm ",
            },
            {
              title: "Overall Height",
              value: "1530 mm",
            },
          ],
          weight: [
            {
              title: "Gross Weight",
              value: "1335 kg",
            },
            {
              title: "Kerb Weight",
              value: "875 - 905",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1197 cc",
            },
            {
              title: "Engine Type",
              value: "-",
            },
            {
              title: "Max Power",
              value: "66.0 kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5AMT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "37 L",
            },
            {
              title: "Boot Space",
              value: "268 L",
            },
          ],
        },
      ],
    },
  ],

  eeco: [
    {
      name: "Maruti EECO",
      banner: require("../assets/banners/eeco.jpeg"),
      price: "4.63",
      engine: "1196",
      petrol: "16.11",
      cng: "20.88",
      power: "-",
      variants: [
        {
          name: "EECO 5 STR STD(O)",
          price: "4,63,200",
        },
        {
          name: "EECO 7 STR STD(O)",
          price: "4,92,200",
        },
        {
          name: "EECO 5 STR AC(O)",
          price: "4,99,200",
        },
        {
          name: "EECO Care Ambulance Shell",
          price: "5,90,000",
        },
        {
          name: "EECO 5 STR AC CNG(O)",
          price: "5,94,200",
        },
        {
          name: "EECO Ambulance(O)",
          price: "7,63,200",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Overall Length",
              value: "3675 mm",
            },
            {
              title: "Overall Width",
              value: "1475 mm ",
            },
            {
              title: "Overall Height",
              value: "1930 mm",
            },
          ],
          weight: [
            {
              title: "Gross Weight",
              value: "1510 kg",
            },
            {
              title: "Kerb Weight",
              value: "1030 kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1196 cc",
            },
            {
              title: "Engine Type",
              value: "G12B",
            },
            {
              title: "Max Power",
              value: "54 kW @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 Speed - MT, Rear Drive",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "3 Link Rigid",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "40 L",
            },
            {
              title: "Boot Space",
              value: "268 L",
            },
          ],
        },
      ],
    },
  ],

  ertiga: [
    {
      name: "Maruti Ertiga",
      banner: require("../assets/banners/ertiga.jpeg"),
      price: "8.35",
      engine: "1462",
      petrol: "20.51",
      cng: "31.59",
      power: "101.6",
      variants: [
        {
          name: "ERTIGA SMART HYBRID LXI 1.5L 5MT",
          price: "8,35,000",
        },
        {
          name: "TOUR M PETROL",
          price: "9,46,000",
        },
        {
          name: "ERTIGA SMART HYBRID VXI 1.5L 5MT",
          price: "9,49,000",
        },
        {
          name: "TOUR M CNG",
          price: "10,41,000",
        },
        {
          name: "ERTIGA VXI CNG 1.5L 5MT",
          price: "10,44,000",
        },
        {
          name: "ERTIGA SMART HYBRID ZXI 1.5L 5MT",
          price: "10,59,000",
        },
        {
          name: "ERTIGA SMART HYBRID VXI 1.5L 6AT",
          price: "10,99,000",
        },
        {
          name: "ERTIGA SMART HYBRID ZXI+ 1.5L 5MT",
          price: "11,29,000",
        },
        {
          name: "ERTIGA ZXI CNG 1.5L 5MT",
          price: "11,54,000",
        },
        {
          name: "ERTIGA SMART HYBRID ZXI 1.5L 6AT",
          price: "12,09,000",
        },
        {
          name: "ERTIGA SMART HYBRID ZXI+ 1.5L 6AT",
          price: "12,79,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "4395 mm",
            },
            {
              title: "Width",
              value: "1735 mm ",
            },
            {
              title: "Height(Unladen)",
              value: "1690 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1785 kg",
            },
            {
              title: "Kerb Weight",
              value: "1150-1205",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1462 cc",
            },
            {
              title: "Engine Type",
              value: "K15C SMART HYBRID",
            },
            {
              title: "Max Power",
              value: "75.8 kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "6 AT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut & Coll Spring",
            },
            {
              title: "Rear",
              value: "Torsion Beam & Coll Spring",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "7",
            },
            {
              title: "Fuel Tank",
              value: "45 L",
            },
            {
              title: "Boot Space",
              value:
                "209 (All 3 Rows Up), 550 (3rd Row Folded) & 803 (2nd Row and 3rd Row Folded)",
            },
          ],
        },
      ],
    },
  ],

  spresso: [
    {
      name: "Maruti S-Presso",
      banner: require("../assets/banners/spresso.jpeg"),
      price: "4.25",
      engine: "998",
      petrol: "25.30",
      cng: "31.2",
      power: "65.71",
      variants: [
        {
          name: "S-Presso STD",
          price: "4,25,000",
        },
        {
          name: "S-Presso LXI",
          price: "4,95,000",
        },
        {
          name: "S-Presso VXI",
          price: "5,15,000",
        },
        {
          name: "S-Presso VXI+",
          price: "5,49,000",
        },
        {
          name: "S-Presso VXI (O) AGS",
          price: "5,65,000",
        },
        {
          name: "S-Presso VXI+ (O) AGS",
          price: "5,99,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3565 mm",
            },
            {
              title: "Width",
              value: "1520 mm ",
            },
            {
              title: "Height(Unladen)",
              value: "1567 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1170 kg",
            },
            {
              title: "Kerb Weight",
              value: "736-775",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "998 cc",
            },
            {
              title: "Engine Type",
              value: "K Series",
            },
            {
              title: "Max Power",
              value: "49 kW @ 5500 RPM",
            },
            {
              title: "Transmission",
              value: "5 MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut & Coll Spring",
            },
            {
              title: "Rear",
              value: "Torsion Beam & Coll Spring",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "27 L",
            },
            {
              title: "Boot Space",
              value:
                "209 (All 3 Rows Up), 550 (3rd Row Folded) & 803 (2nd Row and 3rd Row Folded)",
            },
          ],
        },
      ],
    },
  ],
};

export const nexa = {
  fronx: [
    {
      name: "Maruti Fronx",
      price: "7,46,500",
      engine: "1462",
      petrol: "-",
      cng: "-",
      power: "103",
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3995 mm",
            },
            {
              title: "Width",
              value: "1765 mm",
            },
            {
              title: "Height",
              value: "1550 mm",
            },
            {
              title: "Wheelbase",
              value: "2520 mm",
            },
            {
              title: "Ground Clearance",
              value: "210 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1450 kg",
            },
            {
              title: "Kerb Weight",
              value: "965 - 970 (MT), 975 (AMT) kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated disc",
            },
            {
              title: "Rear",
              value: "Drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1197 cc",
            },
            {
              title: "Engine Type",
              value: "1.2L Dual Jet, Dual VVT",
            },
            {
              title: "Power",
              value: "66 kW@6000rpm or 89.73 PS@6000rpm",
            },
            {
              title: "Transmission",
              value: "5MT / 5AMT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "MacPherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "37 L",
            },
            {
              title: "Boot Space",
              value: "308 L",
            },
          ],
        },
      ],
    },
  ],

  invicto: [
    {
      name: "Maruti Invicto",
      price: "24,79,000",
      engine: "1987",
      petrol: "23.24",
      cng: "-",
      power: "112",
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "4755 mm",
            },
            {
              title: "Width",
              value: "1850 mm",
            },
            {
              title: "Height",
              value: "1790 mm",
            },
            {
              title: "Wheelbase",
              value: "2850 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "2320 kg",
            },
            {
              title: "Kerb Weight",
              value: "1685 kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated Disc",
            },
            {
              title: "Rear",
              value: "Solid Disc",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1997 cc",
            },
            {
              title: "Power",
              value: "112 kW @6000 rpm",
            },
            {
              title: "Transmission",
              value: "e-CVT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "MacPherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "7",
            },
            {
              title: "Fuel Tank",
              value: "52 L",
            },
            {
              title: "Boot Space",
              value: "308 L",
            },
          ],
        },
      ],
    },
  ],

  jimny: [
    {
      name: "Maruti Jimny",
      price: "-",
      engine: "1462",
      petrol: "-",
      cng: "-",
      power: "103",
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3985 mm",
            },
            {
              title: "Width",
              value: "1645 mm",
            },
            {
              title: "Height",
              value: "1720 mm",
            },
            {
              title: "Wheelbase",
              value: "2590 mm",
            },
            {
              title: "Ground Clearance",
              value: "210 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1545 kg",
            },
            {
              title: "Kerb Weight",
              value: "1195-1200 (MT), 1205-1210 (AT) kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated disc",
            },
            {
              title: "Rear",
              value: "Drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1462 cc",
            },
            {
              title: "Engine Type",
              value: "-",
            },
            {
              title: "Power",
              value: "77.1kW@6000 rpm [104.8Ps@6000 rpm]",
            },
            {
              title: "Transmission",
              value: "5MT, 4AT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "3-link Rigid Axle Type with Coil Spring",
            },
            {
              title: "Rear",
              value: "3-link Rigid Axle Type with Coil Spring",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "4",
            },
            {
              title: "Fuel Tank",
              value: "40 L",
            },
            {
              title: "Boot Space",
              value: "208 L",
            },
            {
              title: "Boot Space (Rear Seat Folded)",
              value: "332 L",
            },
          ],
        },
      ],
    },
  ],

  xl6: [
    {
      name: "Maruti XL6",
      banner: require("../assets/xl6/1.webp"),
      price: "11.29",
      engine: "1462",
      petrol: "20.97",
      cng: "-",
      power: "101.65",
      variants: [
        {
          name: "XL6 ZETA",
          price: "11,29,000",
        },
        {
          name: "XL6 ALPHA",
          price: "12,29,000",
        },
        {
          name: "XL6 ZETA AUTOMATIC",
          price: "12,79,000",
        },
        {
          name: "XL6 ALPHA+",
          price: "12,89,000",
        },
        {
          name: "XL6 ALPHA AUTOMATIC",
          price: "13,79,000",
        },
        {
          name: "XL6 ALPHA+ AUTOMATIC",
          price: "14,39,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "4445 mm",
            },
            {
              title: "Width",
              value: "1775 mm",
            },
            {
              title: "Height",
              value: "1755 mm",
            },
            {
              title: "Wheelbase",
              value: "2740 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1740 (MT), 1765 (AT)",
            },
            {
              title: "Kerb Weight",
              value: "1200 (MT), 1225 (AT)",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1462 cc",
            },
            {
              title: "Engine Type",
              value: "K15C Smart Hybrid",
            },
            {
              title: "Power",
              value: "75.8 @ 6000",
            },
            {
              title: "Transmission",
              value: "5 MT / 6AT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut & Coil Spring",
            },
            {
              title: "Rear",
              value: "Torsion Beam & Coil Spring",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "6",
            },
            {
              title: "Fuel Tank",
              value: "45 L",
            },
            {
              title: "Boot Space",
              value:
                "209* (All 3 Rows Up), 550* (3rd Row Folded) & 692* (2nd Row and 3rd Row Folded)",
            },
          ],
        },
      ],
    },
  ],

  ciaz: [
    {
      name: "Maruti Ciaz",
      banner: require("../assets/banners/ciaz.webp"),
      price: "8.99",
      engine: "1462",
      petrol: "20.65",
      cng: "-",
      power: "103.25",

      variants: [
        {
          name: "CIAZ SMART HYBRID SIGMA",
          price: "8,99,500",
        },
        {
          name: "CIAZ SMART HYBRID DELTA",
          price: "9,63,500",
        },
        {
          name: "CIAZ SMART HYBRID ZETA",
          price: "9,99,000",
        },
        {
          name: "CIAZ SMART HYBRID ALPHA",
          price: "10,78,500",
        },
        {
          name: "CIAZ SMART HYBRID AUTOMATIC DELTA",
          price: "10,83,500",
        },
        {
          name: "CIAZ SMART HYBRID CIAZ S",
          price: "10,89,500",
        },
        {
          name: "CIAZ SMART HYBRID AUTOMATIC ZETA",
          price: "11,19,000",
        },
        {
          name: "CIAZ SMART HYBRID AUTOMATIC ALPHA",
          price: "11,98,500",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "4490 mm",
            },
            {
              title: "Width",
              value: "1730 mm",
            },
            {
              title: "Height",
              value: "1485 mm",
            },
            {
              title: "Wheelbase",
              value: "2650 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1520 (MT)/1530 (AT)",
            },
            {
              title: "Kerb Weight",
              value: "-",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated Disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1462 cc",
            },
            {
              title: "Engine Type",
              value: "K15 Smart Hybrid Petrol Engine",
            },
            {
              title: "Power",
              value: "77kw @ 6000rpm",
            },
            {
              title: "Transmission",
              value: "5MT/4AT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "45 L",
            },
            {
              title: "Boot Space",
              value: "510*",
            },
          ],
        },
      ],
    },
  ],

  baleno: [
    {
      name: "Maruti Baleno",
      banner: require("../assets/banners/maruti-baleno.jpg"),
      price: "6.49",
      engine: "1197",
      petrol: "22.94",
      cng: "-",
      power: "88.5",

      variants: [
        {
          name: "Baleno Sigma Petrol",
          price: "6,49,000",
        },
        {
          name: "Baleno Delta Petrol",
          price: "7,33,000",
        },
        {
          name: "Baleno Delta Petrol AGS",
          price: "7,83,000",
        },
        {
          name: "Baleno Zeta Petrol",
          price: "8,26,000",
        },
        {
          name: "Baleno Zeta Petrol AGS",
          price: "8,76,000",
        },
        {
          name: "Baleno Alpha Petrol",
          price: "9,21,000",
        },
        {
          name: "Baleno Alpha Petrol AGS",
          price: "9,71,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3990 mm",
            },
            {
              title: "Width",
              value: "1745 mm",
            },
            {
              title: "Height",
              value: "1500 mm",
            },
            {
              title: "Wheelbase",
              value: "2520 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1410",
            },
            {
              title: "Kerb Weight",
              value: "920-955 (MT), 935-960 (AGS)",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1197 cc",
            },
            {
              title: "Power",
              value: "66kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5MT/5AGS",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "37 L",
            },
            {
              title: "Boot Space",
              value: "318*",
            },
          ],
        },
      ],
    },
  ],

  ignis: [
    {
      name: "Maruti Ignis",
      banner: require("../assets/banners/ignis.webp"),
      price: "5.35",
      engine: "1197",
      petrol: "20.89",
      cng: "-",
      power: "81.8",
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3700 mm",
            },
            {
              title: "Width",
              value: "1690 mm",
            },
            {
              title: "Height",
              value: "1595 mm",
            },
            {
              title: "Wheelbase",
              value: "2435 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "-",
            },
            {
              title: "Kerb Weight",
              value: "840 - 865",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Type",
              value: "VVT",
            },
            {
              title: "Engine Capacity",
              value: "1197 cc",
            },
            {
              title: "Power",
              value: "61kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 MT / AMT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "32 L",
            },
            {
              title: "Boot Space",
              value: "260*",
            },
          ],
        },
      ],
    },
  ],

  grand_vitara: [
    {
      name: "Grand Vitara",
      banner: require("../assets/banners/grand_vitara.webp"),
      price: "10.45",
      engine: "1462",
      petrol: "-",
      cng: "-",
      power: "101.6",
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "4345 mm",
            },
            {
              title: "Width",
              value: "1795 mm",
            },
            {
              title: "Height",
              value: "1645 mm",
            },
            {
              title: "Wheelbase",
              value: "2600 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1645* (Varies by model)",
            },
            {
              title: "Kerb Weight",
              value: "1185",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated Disc",
            },
            {
              title: "Rear",
              value: "Solid Disc",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1462 cc",
            },
            {
              title: "Power",
              value: "68kw @ 5500",
            },
            {
              title: "Transmission",
              value: "5 MT / 6 AT / e-CVT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "45 L",
            },
          ],
        },
      ],
    },
  ],
};

export const commercial = {
  tourh1: [
    {
      name: "Maruti Tour H1",
      banner: require("../assets/banners/tour/TourH1.webp"),
      price: "4.05",
      engine: "796",
      petrol: "22.05",
      cng: "-",
      power: "47.33",
      variants: [
        {
          name: "TOUR H1 (O)",
          price: "4,05,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3445 mm",
            },
            {
              title: "Width (w/o side moulding)",
              value: "1490 mm",
            },
            {
              title: "Height",
              value: "1475 mm",
            },
            {
              title: "Wheelbase",
              value: "2360 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1185",
            },
            {
              title: "Kerb Weight",
              value: "755 / 757 (O) kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "796 cc",
            },
            {
              title: "Engine Type",
              value: "F8D",
            },
            {
              title: "Power",
              value: "35.3 kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "3-link rigid axle suspension",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "35 L",
            },
          ],
        },
      ],
    },
  ],

  tourh3: [
    {
      name: "Maruti Tour H3",
      banner: require("../assets/banners/tour/TourH3.webp"),
      price: "5.44",
      engine: "998",
      petrol: "24.5",
      cng: "34.73",
      power: "-",
      variants: [
        {
          name: "TOur H3 CNG",
          price: "6,39,500",
        },
        {
          name: "Tour H3 Petrol",
          price: "5,44,500",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3655 mm",
            },
            {
              title: "Width",
              value: "1620 mm",
            },
            {
              title: "Height",
              value: "1675 mm",
            },
            {
              title: "Wheelbase",
              value: "2435 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1340",
            },
            {
              title: "Kerb Weight",
              value: "810",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated disc",
            },
            {
              title: "Rear",
              value: "Solid Disc",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "998 cc",
            },
            {
              title: "Engine Type",
              value: "K10C",
            },
            {
              title: "Power",
              value: "49 kW @5500 rpm (Petrol mode)",
            },
            {
              title: "Transmission",
              value: "5MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut with Coil Spring",
            },
            {
              title: "Rear",
              value: "Torsion Beam with Coil Spring",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "32 L",
            },
            {
              title: "Boot Space",
              value: "341",
            },
          ],
        },
      ],
    },
  ],

  tourm: [
    {
      name: "Maruti Tour M",
      banner: require("../assets/banners/tour/TourM.webp"),
      price: "9.46",
      engine: "1462",
      petrol: "18.04",
      cng: "26.08",
      power: "91.8",
      variants: [
        {
          name: "Tour M Petrol",
          price: "8,99,500",
        },
        {
          name: "Tour M CNG",
          price: "10,41,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "4395 mm",
            },
            {
              title: "Width",
              value: "1735 mm",
            },
            {
              title: "Height",
              value: "1690 mm",
            },
            {
              title: "Wheelbase",
              value: "2740 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1760",
            },
            {
              title: "Kerb Weight",
              value: "1170",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated Disc",
            },
            {
              title: "Rear",
              value: "(Leading and Trailing) Drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1462 cc",
            },
            {
              title: "Engine Type",
              value: "K15C SMART HYBRID",
            },
            {
              title: "Power",
              value: "75kw @ 6000rpm",
            },
            {
              title: "Transmission",
              value: "5MT / 6AT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut & Coil Spring",
            },
            {
              title: "Rear",
              value: "Torsion Beam & Coil Spring",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "7",
            },
            {
              title: "Fuel Tank",
              value: "- L",
            },
            {
              title: "Boot Space",
              value:
                "209(All 3 Rows Up), 550(3rd Row Folded) & 803(2nd Row and 3rd Row Folded)",
            },
          ],
        },
      ],
    },
  ],

  tours: [
    {
      name: "Maruti Tour S",
      banner: require("../assets/banners/tour/TourS.webp"),
      price: "6.21",
      engine: "1197",
      petrol: "19.95",
      cng: "26.55",
      power: "81.8",
      variants: [
        {
          name: "Tour S Petrol",
          price: "6,21,000",
        },
        {
          name: "Tour S CNG",
          price: "7,16,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3995 mm",
            },
            {
              title: "Width",
              value: "1695 mm",
            },
            {
              title: "Height",
              value: "1555 mm",
            },
            {
              title: "Wheelbase",
              value: "2430 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1415",
            },
            {
              title: "Kerb Weight",
              value: "945",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Ventilated Disc",
            },
            {
              title: "Rear",
              value: "(Leading and Trailing) Drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1197 cc",
            },
            {
              title: "Engine Type",
              value: "-",
            },
            {
              title: "Power",
              value: "61 kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "42 L",
            },
            {
              title: "Boot Space",
              value: "-",
            },
          ],
        },
      ],
    },
  ],

  tourv: [
    {
      name: "Maruti Tour V",
      banner: require("../assets/banners/tour/tourv.webp"),
      price: "4.60",
      engine: "1197",
      petrol: "16.11",
      cng: "20.88",
      power: "-",
      variants: [
        {
          name: "TOUR V 5 STR AC CNG(O)",
          price: "5,91,200",
        },
        {
          name: "TOUR V 5 STR STD(O)",
          price: "4,60,200",
        },
        {
          name: "TOUR V 7 STR STD(O)",
          price: "4,89,200",
        },
        {
          name: "TOUR V 5 STR AC(O)",
          price: "4,96,200",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3675 mm",
            },
            {
              title: "Width",
              value: "1475 mm",
            },
            {
              title: "Height",
              value: "1825 mm",
            },
            {
              title: "Wheelbase",
              value: "2350 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1510",
            },
            {
              title: "Kerb Weight",
              value: "940",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Type",
              value: "G12B",
            },
            {
              title: "Engine Capacity",
              value: "1196 cc",
            },
            {
              title: "Power",
              value: "54 kw @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 Speed MT, Rear Drive",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
            {
              title: "Rear",
              value: "3 Link Rigid",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "40 L",
            },
            {
              title: "Boot Space",
              value: "-",
            },
          ],
        },
      ],
    },
  ],
  supercarry: [
    {
      name: "Maruti Suzuki Super Carry",
      banner: require("../assets/banners/tour/supercarry.webp"),
      price: "4.60",
      engine: "-",
      petrol: "-",
      cng: "-",
      power: "-",
      variants: [
        {
          name: "Super Carry CNG",
          price: "5,93,000",
        },
        {
          name: "Super Carry Petrol Deckless",
          price: "4,73,000",
        },
        {
          name: "Super Carry Petrol",
          price: "4,88,000",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3800 mm",
            },
            {
              title: "Width",
              value: "1562 mm",
            },
            {
              title: "Height",
              value: "1883 mm",
            },
            {
              title: "Wheelbase",
              value: "2110 mm",
            },
            {
              title: "Front Track",
              value: "1345 mm",
            },
            {
              title: "Rear Track",
              value: "1330 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1600",
            },
            {
              title: "Kerb Weight",
              value: "860",
            },
            {
              title: "Payload",
              value: "740",
            },
          ],
          brakes: [
            {
              title: "Brake Control Valve",
              value: "LSPV",
            },
            {
              title: "Front",
              value: "Ventilated disc",
            },
            {
              title: "Rear",
              value: "Drum",
            },
          ],
          engine: [
            {
              title: "Engine Type",
              value: "Multi Point Fuel Injuction G12B BS-VI",
            },
            {
              title: "Engine Capacity",
              value: "1.2L Petrol Engine",
            },
            {
              title: "Power",
              value: "54 kW @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 Speed MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut with Coil Spring",
            },
            {
              title: "Rear",
              value: "Leaf Spring Rigid Axle",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "2(Driver + 1)",
            },
            {
              title: "Fuel Tank",
              value: "30 L",
            },
          ],
        },
      ],
    },
  ],
  cargo: [
    {
      name: "Maruti Suzuki EECO Cargo",
      banner: require("../assets/banners/tour/cargo.webp"),
      price: "4.60",
      engine: "1196",
      petrol: "-",
      cng: "-",
      power: "-",
      variants: [
        {
          name: "EECO Cargo CNG",
          price: "5,73,200",
        },
        {
          name: "EECO Cargo CNG AC",
          price: "6,15,200",
        },
        {
          name: "EECO Cargo Petrol",
          price: "4,78,200",
        },
      ],
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3675 mm",
            },
            {
              title: "Width",
              value: "1475 mm",
            },
            {
              title: "Height",
              value: "1825 mm",
            },
            {
              title: "Wheelbase",
              value: "2350 mm",
            },
            {
              title: "Tread Front",
              value: "1280 mm",
            },
            {
              title: "Tread Rear",
              value: "1290 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "-",
            },
            {
              title: "Kerb Weight",
              value: "920",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Disc",
            },
            {
              title: "Rear",
              value: "Drum",
            },
          ],
          engine: [
            {
              title: "Engine Type",
              value: "G12B",
            },
            {
              title: "Engine Capacity",
              value: "1196",
            },
            {
              title: "Power",
              value: "54 kW @ 6000 rpm",
            },
            {
              title: "Transmission",
              value: "5 Speed MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "Mac Pherson Strut",
            },
          ],
          capacity: [
            {
              title: "Fuel Tank",
              value: "40 L",
            },
          ],
        },
      ],
    },
  ],
};

export const cng = {
  baleno: [
    {
      name: "Maruti Baleno CNG",
      banner: require("../assets/banners/baleno-cng.webp"),
      price: "8.30",
      engine: "1197",
      petrol: "-",
      cng: "30.61",
      power: "76",
      specifications: [
        {
          dimensions: [
            {
              title: "Overall Length",
              value: "3990 mm",
            },
            {
              title: "Overall Width",
              value: "1745",
            },
            {
              title: "Overall Height(Unladen)",
              value: "1500 mm",
            },
            {
              title: "Wheelbase (mm)",
              value: "2520",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1450 kg",
            },
            {
              title: "Kerb Weight",
              value: "1015-1030 (MT) kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1197 cc",
            },
            {
              title: "Max Power",
              value: "66 kW@6000 rpm ",
            },
            {
              title: "Transmission",
              value: "5 MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "MacPherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "55 (Water Equivalent)",
            },
            {
              title: "Boot Space",
              value: "-",
            },
          ],
        },
      ],
    },
  ],

  xl6: [
    {
      name: "Maruti Xl6 CNG",
      banner: require("../assets/banners/XL6-cng-banner.webp"),
      price: "12.36",
      engine: "1462",
      petrol: "-",
      cng: "26.32",
      power: "86.63",
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "4445 mm",
            },
            {
              title: "Width",
              value: "1775",
            },
            {
              title: "Height(Unladen)",
              value: "1755 mm",
            },
            {
              title: "Wheelbase (mm)",
              value: "2740",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1810 (MT) kg",
            },
            {
              title: "Kerb Weight",
              value: "1275 (MT) kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "disc",
            },
            {
              title: "Rear",
              value: "drum",
            },
          ],
          engine: [
            {
              title: "Engine Type",
              value: "K15C cc",
            },
            {
              title: "Engine Capacity",
              value: "1462 cc",
            },
            {
              title: "Max Power",
              value: "64.6 @ 5500 rpm",
            },
            {
              title: "Transmission",
              value: "5 MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "MacPherson Strut & Coil",
            },
            {
              title: "Rear",
              value: "Torsion Beam & Coil",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "6",
            },
            {
              title: "Fuel Tank",
              value: "60 (Water Equivalent)",
            },
            {
              title: "Boot Space",
              value: "-",
            },
          ],
        },
      ],
    },
  ],

  fronx: [
    {
      name: "Fronx CNG",
      banner: require("../assets/banners/fronx-cng.webp"),
      price: "7,46,500",
      engine: "1462",
      petrol: "-",
      cng: "28.51(MT)",
      power: "103",
      specifications: [
        {
          dimensions: [
            {
              title: "Length",
              value: "3995 mm",
            },
            {
              title: "Width",
              value: "1765 mm",
            },
            {
              title: "Height",
              value: "1550 mm",
            },
            {
              title: "Wheelbase",
              value: "2520 mm",
            },
            {
              title: "Ground Clearance",
              value: "210 mm",
            },
          ],
          weight: [
            {
              title: "Gross Vehicle Weight",
              value: "1480 kg",
            },
            {
              title: "Kerb Weight",
              value: "1050kg - 1060kg",
            },
          ],
          brakes: [
            {
              title: "Front",
              value: "Disc",
            },
            {
              title: "Rear",
              value: "Drum",
            },
          ],
          engine: [
            {
              title: "Engine Capacity",
              value: "1197 cc",
            },
            {
              title: "Engine Type",
              value: "1.2L Dual Jet, Dual VVT",
            },
            {
              title: "Power",
              value: "77.5 Ps @6000 rpm",
            },
            {
              title: "Transmission",
              value: "5MT",
            },
          ],
          suspension: [
            {
              title: "Front",
              value: "MacPherson Strut",
            },
            {
              title: "Rear",
              value: "Torsion Beam",
            },
          ],
          capacity: [
            {
              title: "Seating",
              value: "5",
            },
            {
              title: "Fuel Tank",
              value: "55L (Water Equivalent)",
            },
            {
              title: "Boot Space",
              value: "308 L",
            },
          ],
        },
      ],
    },
  ],
};
