import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { FaRegEye } from "react-icons/fa";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { TbNotes } from "react-icons/tb";

function About() {
  return (
    <>
      <Header />
      <img
        src={require("../assets/Pavan-Motors-Location-Map.png")}
        className="w-full lg:h-[550px] object-cover"
        alt="about banner"
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / About
        </p>
      </div>
      <AboutUs />
      <Footer />
    </>
  );
}

const AboutUs = () => {
  return (
    <div className="px-5 lg:px-0">
      <div className="container mx-auto mt-16 mb-8">
        <div className="flex justify-center">
          <p className="text-red-600 p-2">About us</p>
        </div>
        <p className="uppercase font-semibold text-4xl text-center mt-3">
          Welcome to pavan motors
        </p>
        <div className="relative">
          <div className="h-1 bg-gray-500 mt-3 max-w-[100px] mx-auto"></div>
        </div>
        <p className="mt-8 max-w-3xl text-center mx-auto">
          Take great pride in creating, what we believe, to be one of the most
          premier dealerships across Telangana State.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-5">
          <div className="col-span-1 bg-gray-100 hover:bg-gray-200 duration-300 text-center">
            <div className="p-8">
              <FaRegEye className="mx-auto text-red-700" size={45} />
              <p className="text-xl mt-5">Our Vision</p>
              <p className="font-light mt-2">
                We pavan motors to reach customers in all possible ways, to
                fulfill to buy the dream car.
              </p>
            </div>
          </div>

          <div className="col-span-1 bg-gray-100 hover:bg-gray-200 duration-300 text-center">
            <div className="p-8">
              <BiMessageRoundedDetail
                className="mx-auto text-red-700"
                size={45}
              />
              <p className="text-xl mt-5">Objectives</p>
              <p className="font-light mt-2">
                To ensure customer delight ness in all the ways ( Sales,
                Service, Finance, Exchange).
              </p>
            </div>
          </div>

          <div className="col-span-1 bg-gray-100 hover:bg-gray-200 duration-300 text-center">
            <div className="p-8">
              <BsPerson className="mx-auto text-red-700" size={45} />
              <p className="text-xl mt-5">Our Mission</p>
              <p className="font-light mt-2">
                One among Top dealers in Telangana state.
              </p>
            </div>
          </div>

          <div className="col-span-1 bg-gray-100 hover:bg-gray-200 duration-300 text-center">
            <div className="p-8">
              <TbNotes className="mx-auto text-red-700" size={45} />
              <p className="text-xl mt-5">Our Values</p>
              <p className="font-light mt-2">Centric approach.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 container border rounded-lg shadow-lg p-5 mx-auto my-7">
        <div className="col-span-1">
          <img
            src={require("../assets/nexa/grandvitara.webp")}
            className="mx-auto"
            alt=""
          />
        </div>
        <div className="col-span-1">
          <p className="font-semibold lg:text-4xl">
            Welcome To The Most <br />{" "}
            <span className="text-red-600">
              Youngest Car Dealer in Hyderabad
            </span>
          </p>
          <p className="mt-8">
            Our team consists of many young and energetic talented expertise in
            Sales & Service with a wide range of knowledge and experience.
          </p>
          <p className="mt-5">
            Since the time of Dealership Commissioned we have maintained (MARUTI
            SUZUKI in 2011, NEXA in 2017), the best strategy for outstanding
            Customer Satisfaction.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto my-10 border rounded-lg shadow-lg p-5">
        <div className="col-span-1">
          <p className="font-semibold lg:text-4xl">
            Pavan Motors Makes you <br />{" "}
            <span className="text-red-600">happy….Deeply!</span>
          </p>
          <p className="mt-8">
            In taking forward the legacy of the PAVAN Group, Komatireddy Chandra
            Pavan Reddy, who is the Managing Director - CEO of PAVAN MOTORS Pvt.
            Ltd, is a post-graduate in Master of Business Administration from
            Cardiff, UK. He is actively involved in the business from the
            Inception of the company. Most Prestigious Award of MARUTI SUZUKI
            has been achieved by Pavan Motors i.e. PLATINUM AWARD consistently
            for five years viz., 2013-2014, 2014-2015, 2015-2016, 2016-2017 &
            2017-18 financial Years under the leadership of Mr. K.Chandra Pavan
            Reddy. Just to add, He is also awarded (FY1617) as the “Youngest
            Entrepreneur” amongst all the Maruti Dealerships PAN India..
          </p>
        </div>
        <div className="col-span-1">
          <img
            src={require("../assets/nexa/xl6.webp")}
            className="mx-auto max-h-96 h-full"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default About;
