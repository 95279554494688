import React from "react";
import { Link } from "react-router-dom";

function OurChannels() {
  return (
    <div className="grid md:grid-cols-3">
      <div className="col-span-1 bg-[#e3f9f6] text-right p-5">
        <p className="uppercase text-black text-9xl text-right font-semibold opacity-20">
          our
        </p>
        <p className="uppercase text-3xl">Channels</p>
      </div>
      <div className="md:col-span-2 px-2">
        <div className="grid grid-cols-3 md:grid-cols-3 sm:grid-cols-3">
          <div className="col-span-1 m-1">
            <Link to="/arena"><img src={require("../../assets/arena.png")} alt="arena" /></Link>
          </div>
          <div className="col-span-1 m-1">
            <Link to="/nexa"><img src={require("../../assets/nexa.png")} alt="nexa" /></Link>
          </div>
          <div className="col-span-1 m-1">
            <Link to="/commercial"><img
              src={require("../../assets/commercial.png")}
              alt="commercial"
            /></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurChannels;
