import React, { useEffect } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import Navbar from "../../components/admin/Navbar";
import { toast } from "react-toastify";

function AllImages() {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };
  const [urls, setUrls] = useState([]);
  useEffect(() => {
    const listRef = ref(storage, "vehicles");
    const fetchData = async () => {
      try {
        await listAll(listRef).then((res) => {
          // for (let index = 0; index < res.items.length; index++) {
          //   const element = res.items[index];
          //   getDownloadURL(element).then((url) => {
          //     console.log(url)
          //   })
          // }
          res.items.forEach(async (itemRef) => {
            await getDownloadURL(itemRef).then((url) => {
              setUrls((prev) => [...prev, url]);
            });
          });
        });
      } catch (error) {
        toast.error("Something went wrong!");
        // console.log(error)
      }
    };
    fetchData();
  }, []);

  console.log(urls.length);
  return (
    <div className="flex flex-row h-screen">
      <Sidebar active={active} />
      <div className="flex-auto bg-gray-50 overflow-auto">
        <Navbar handleActive={handleActive} />
        <div className="mt-5 grid grid-cols-3 container mx-auto gap-6">
          {urls.map((item, i) => (
            <img src={item} key={i} alt="" />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AllImages;
