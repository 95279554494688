import React from "react";
import { Link } from "react-router-dom";
import { ImRoad } from "react-icons/im";
import { TbEngine } from "react-icons/tb";
import { MdAirlineSeatReclineExtra } from "react-icons/md";

function Car({ img, name, price, kmpl, seats, engine, link }) {
  return (
    <>
      <div className="rounded overflow-hidden bg-gray-50 flex flex-col">
        <Link
          to={link}
          className="flex-2 p-2 rounded overflow-hidden border border-gray-100"
        >
          <img
            src={img}
            loading="lazy"
            className="w-full h-[210px] object-cover rounded-lg"
            alt={name}
          />
        </Link>
        <div className="flex-1 px-5 space-y-1">
          <div className="flex items-center justify-between">
            <Link to={link} className="text-lg hover:text-red-600">
              {name}
            </Link>
            <p className="font-semibold text-center">
              <span className="font-light">Ex Showroom</span> <br /> ₹ {price}*
            </p>
          </div>
          <p className="bg-gray-200 h-px"></p>
          <div className="flex items-center justify-between">
            <p className="flex items-center">
              <TbEngine className="mr-1" />
              {engine} cc
            </p>
            <p className="bg-gray-200 w-px h-10"></p>
            <p className="flex items-center">
              <ImRoad className="mr-1" /> {kmpl} kmpl
            </p>
            <p className="bg-gray-200 w-px h-10"></p>
            <p className="flex items-center">
              <MdAirlineSeatReclineExtra className="mr-1" />
              {seats} Seats
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Car;
