import React from "react";

function Capacity({ specs }) {
  return (
    <table className="border-collapse border border-slate-500 w-full mt-5">
      <tbody>
        {specs?.map((data) =>
          data.specifications.map((feature) =>
            feature.capacity.map((item, i) => (
              <tr key={i}>
                <td className="border px-5 py-2.5 font-medium">{item.title}</td>
                <td className="border px-5 py-2.5 font-light capitalize">
                  {item.value !== "" ? item.value : "N/A"}
                </td>
              </tr>
            ))
          )
        )}
      </tbody>
    </table>
  );
}

export default Capacity;
