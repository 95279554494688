import React from "react";

function Weight({ specs }) {
  return (
    <table className="border-collapse border border-slate-500 w-full mt-5">
      <tbody>
        {specs.map((data) =>
          data.specifications.map((feature) =>
            feature.weight.map((item, i) => (
              <tr key={i}>
                <td className="border px-5 py-2.5 font-medium">{item.title}</td>
                <td className="border px-5 py-2.5 font-light">{item.value}</td>
              </tr>
            ))
          )
        )}
      </tbody>
    </table>
  );
}

export default Weight;
