export const sliders = [
  {
    img: require("../assets/banners/swift-main.webp"),
  },
  {
    img: require("../assets/banners/fronx.webp"),
  },
  {
    img: require("../assets/banners/jimny.webp"),
  },
  {
    img: require("../assets/banners/1.webp"),
  },
  {
    img: require("../assets/banners/XL6_CNG_ web.webp"),
  },
  {
    img: require("../assets/banners/Spresso_Web Banner.webp"),
  },
];
