import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { CgSpinner } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { db } from "../firebase";

function Insurance() {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const pattern = /^[6-9][0-9]{6,9}$/;
  if (phone !== "" && phone.length === 10 && !pattern.test(phone)) {
    toast.error("Enter valid phone number", {
      theme: "colored",
    });
  }

  let navigate = useNavigate();

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const dataCollectionRef = collection(db, "enquiries");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (name !== "" && phone !== "") {
      try {
        await addDoc(dataCollectionRef, {
          name: name,
          email: email,
          phone: phone,
          timestamp: dateTime,
        });
        navigate("/thank-you");
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please fill all the fields");
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Maruti Suzuki Car Insurance Providers in Hyderabad | Pavan Motors"
        />
        <meta
          name="description"
          content="Maruti Suzuki car insurance providers in Hyderabad , Telangana. Get cashless accident repairs, fair & transparent claim settlement. Looking to Renew Maruti car insurance Online call now."
        />
        <meta
          name="keywords"
          content="maruti insurance renewal, saboomaruti in car, suzuki renewal"
        />
      </Helmet>
      <Header />
      <img
        src={require("../assets/insurance.png")}
        className="w-full lg:h-[550px] object-cover"
        alt="inusrance banner"
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / Insurance
        </p>
      </div>
      <div className="container mx-auto py-16 px-5">
        <p className="text-red-500 font-semibold text-2xl">Maruti Insurance</p>
        <form onSubmit={handleSubmit} className="mt-5 space-y-3">
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-3">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                type="text"
                maxLength="10"
                minLength="10"
                required
                value={phone}
                onChange={(e) =>
                  setPhone(
                    e.target.value.replace(/[^1-9 ]/g, "") &&
                      e.target.value.replace(/ /g, "")
                  )
                }
              />
              {!pattern.test(phone) && phone.length === 10 ? (
                <small className="text-red-500">phone number is invalid</small>
              ) : (
                ""
              )}
            </div>
          </div>
          <p className="text-gray-700">
            <span className="text-black font-bold">Disclaimer</span>: I agree
            that by clicking the ‘Submit’ button below, I am explicitly
            soliciting a call / Message from Pavan Motor Pvt. Ltd or its
            Representatives on my ‘Mobile’
          </p>
          <div className="flex items-start">
            <input
              id="disclaimer"
              name="disclaimer"
              type="checkbox"
              className="h-4 w-4 rounded"
            />
            <div className="ml-2 text-sm">
              <label htmlFor="disclaimer" className="font-medium text-gray-700">
                Please Agree with Following Disclaimer
              </label>
            </div>
          </div>
          <button
            className="bg-blue-800 hover:bg-red-500 duration-500 text-white rounded py-2.5 px-5"
            type="submit"
            disabled={pattern.test(phone) && phone.length === 10 ? false : true}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <CgSpinner className="animate-spin h-5 mr-2 text-white w-5" />
                Loading
              </div>
            ) : (
              "Get Your Insurance Now"
            )}
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default Insurance;
