import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { CgSpinner } from "react-icons/cg";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import { db } from "../../firebase";

function EditVariant() {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };

  // Starts from here
  const [data, setData] = useState();
  const [inputData, setInputData] = useState([{ variant: "", price: "" }]);
  const [loading, setLoading] = useState(false);
  //   const handleAdd = (e) => {
  //     e.preventDefault();
  //     setInputData([...inputData, { variant: "", price: "" }]);
  //   };

  //   const handleRemove = (i) => {
  //     const list = [...inputData];
  //     list.splice(i, 1);
  //     setInputData(list);
  //   };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputData];
    list[index][name] = value;
    setInputData(list);
  };

  console.log(inputData);

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const { id } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const docRef = doc(db, "vehicleImages", id);
      await updateDoc(docRef, {
        prices: inputData,
        timestamp: dateTime,
      });
      toast.success("Updated Successfully", {
        theme: "colored",
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "prices", id);
        const docSnap = await getDoc(docRef);
        setData(docSnap.data());
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
    setLoading(false);
  }, [id]);

  console.log(data);

  return (
    <div className="flex flex-row h-screen">
      <Sidebar active={active} />
      <div className="flex-auto bg-gray-50 overflow-auto">
        <Navbar handleActive={handleActive} />
        <form onSubmit={handleSubmit} className="m-10">
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="col-span-6 sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                  Category <span className="text-red-500">*</span>
                </label>
                <input
                  value={data?.name}
                  readOnly
                  className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 px-4 py-5 bg-white sm:p-6">
              {data?.prices.map((x, i) => (
                <>
                  <div className="col-span-1 mr-4 mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Variant <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleInputChange(e, i)}
                      name="variant"
                      value={x.variant}
                      className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-1 mr-4 mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Price <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleInputChange(e, i)}
                      name="price"
                      value={x.price}
                      className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </>
              ))}
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <CgSpinner className="animate-spin h-5 mr-2 text-white w-5" />
                    Loading...
                  </div>
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditVariant;
