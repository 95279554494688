import { doc, getDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import { db } from "../../firebase";

function ViewVariant() {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };

  // Starts from here
  const [data, setData] = useState();
  const [inputData, setInputData] = useState([{ variant: "", price: "" }]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputData];
    list[index][name] = value;
    setInputData(list);
  };

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "prices", id);
        const docSnap = await getDoc(docRef);
        setData(docSnap.data());
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    fetchData();
    setLoading(false);
  }, [id]);

  return (
    <div className="flex flex-row h-screen">
      <Sidebar active={active} />
      <div className="flex-auto bg-gray-50 overflow-auto">
        <Navbar handleActive={handleActive} />
        <form className="m-10">
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="col-span-6 sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                  Category <span className="text-red-500">*</span>
                </label>
                <input
                  value={data?.name}
                  readOnly
                  className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 px-4 py-5 bg-white sm:p-6">
              {data?.prices.map((x, i) => (
                <>
                  <div className="col-span-1 mr-4 mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Variant <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleInputChange(e, i)}
                      name="variant"
                      value={x.variant}
                      readOnly
                      className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-1 mr-4 mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Price <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleInputChange(e, i)}
                      name="price"
                      value={x.price}
                      readOnly
                      className="mt-1 capitalize outline-none p-3 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ViewVariant;
