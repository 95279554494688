import { addDoc, collection } from "firebase/firestore";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { db } from "../firebase";

function DrivingSchool() {
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Maruti Driving School Locations in Hyderabad | Fees & Offers on Maruti Driving School"
        />
        <meta
          name="description"
          content="Maruti Driving School Locations in Hyderabad. Vist our site to know your nearest Maruti Driving School Locationor Call now 7093711199."
        />
        <meta
          name="keywords"
          content="maruti driving school, maruti authorized driving school, maruti driving school near me"
        />
      </Helmet>
      <Header />
      <img
        src={require("../assets/Driving-School.png")}
        className="w-full"
        alt="1"
      />
      {/* <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4"><Link to='/'>Home</Link> / Driving School</p>
      </div> */}
      <RegisterInterest />
      <Confidence />
      <LearnerCourse />
      <LearnerExtendedCourse />
      <DetailedCourse />
      <AdvanceCourse />
      <Footer />
    </>
  );
}

const RegisterInterest = () => {
  const [name, setName] = useState();
  const [phone, setPhone] = useState("");
  const [outlet, setOutlet] = useState();
  const [email, setEmail] = useState();

  const [loading, setLoading] = useState(false);
  const pattern = /^[6-9][0-9]{6,9}$/;

  let navigate = useNavigate();

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const dataCollectionRef = collection(db, "enquiries");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (name !== "" && phone !== "") {
      try {
        await addDoc(dataCollectionRef, {
          name: name,
          email: email,
          phone: phone,
          outlet: outlet,
          timestamp: dateTime,
        });
        navigate("/thank-you");
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please fill all the fields");
    }
    setLoading(false);
  };

  return (
    <div className="py-12 bg-[#051641]">
      <div className="container mx-auto space-y-3 lg:px-0 px-5">
        <h3 className="text-xl font-semibold text-white uppercase">
          Register your interest
        </h3>
        <form>
          <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-4">
            <div>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <input
                className="border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                placeholder="Mobile"
                value={phone}
                minLength="10"
                maxLength="10"
                onChange={(e) =>
                  setPhone(
                    e.target.value.replace(/[^1-9 ]/g, "") &&
                      e.target.value.replace(/ /g, "")
                  )
                }
              />
              {!pattern.test(phone) && phone.length === 10 ? (
                <small className="text-blue-500">phone number is invalid</small>
              ) : (
                ""
              )}
            </div>

            <>
              <select
                onChange={(e) => setOutlet(e.target.value)}
                className="block w-full h-10 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option>Select Outlet</option>
                <option value="MDS-Hyderabad">MDS-Hyderabad</option>
                <option value="MDS-Nalgonda">MDS-Nalgonda</option>
              </select>
            </>

            <div>
              <button
                type="submit"
                disabled={
                  pattern.test(phone) && phone.length === 10 ? false : true
                }
                onClick={handleSubmit}
                className="w-full h-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-900 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <CgSpinner className="animate-spin h-5 mr-2 text-white w-5" />
                    Loading...
                  </div>
                ) : (
                  "Enquiry"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const Confidence = () => {
  return (
    <div className="container mx-auto p-5 my-7">
      <p className="font-semibold  text-2xl">
        CHOOSE YOUR COURSE TO DRIVE WITH CONFIDENCE ON THE ROAD
      </p>
      <p className="text-gray-600 text-sm mt-3">
        Our meticulously designed courses help transform beginners into skilled
        and confident drivers. Choose your desired course from a range of
        driving training courses and master the skill of driving at Saboo Maruti
        Suzuki Driving School.
      </p>
    </div>
  );
};

const LearnerCourse = () => {
  return (
    <div className="bg-white container mx-auto shadow rounded border p-5 mb-8">
      <p className="text-[#051641] font-semibold mb-5">
        Learner Standard Track Course
      </p>
      <div className="grid sm:grid-cols-2">
        <div>
          <p className="my-5">
            If you’ve never been behind the steering wheel, this course is for
            you. At the end of 21 days, you’ll know the basic traffic rules and
            have a hands-on driving experience through simulators and on-road
            driving. What’s more, you’ll have the confidence of taking the RTO
            driving exam to obtain a permanent driving license.
          </p>
          <a
            href="tel:7093711199"
            className="px-4 py-1.5 bg-[#051641] rounded text-white uppercase"
          >
            Interested
          </a>
        </div>
        <img src={require("../assets/driving/Learner-Course.png")} alt="1" />
      </div>
    </div>
  );
};

const LearnerExtendedCourse = () => {
  return (
    <div className="bg-white container mx-auto shadow rounded border p-5 mb-8">
      <p className="text-[#051641] font-semibold mb-5">
        Learner Extended Track Course
      </p>
      <div className="grid sm:grid-cols-2">
        <img
          src={require("../assets/driving/Learner-Extended-Track-Course.png")}
          alt="1"
        />
        <div>
          <p className="my-5">
            If you’ve never been behind the steering wheel, this course is for
            you. At the end of 26 days, you’ll know the basic traffic rules and
            have a hands-on driving experience through simulators and on-road
            driving. What’s more, you’ll have the confidence of taking the RTO
            driving exam to obtain a permanent driving license.
          </p>
          <a
            href="tel:7093711199"
            className="px-4 py-1.5 bg-[#051641] rounded text-white uppercase"
          >
            Interested
          </a>
        </div>
      </div>
    </div>
  );
};

const DetailedCourse = () => {
  return (
    <div className="bg-white container mx-auto shadow rounded border p-5 mb-7">
      <div className="grid sm:grid-cols-2">
        <div>
          <p className="text-[#051641] font-semibold mb-5">
            Learner Detailed Track Course
          </p>
          <p className="my-5">
            If you’ve never been behind the steering wheel, this course is for
            you. At the end of 31 days, you’ll know the basic traffic rules and
            have a hands-on driving experience through simulators and on-road
            driving. What’s more, you’ll have the confidence of taking the RTO
            driving exam to obtain a permanent driving license.
          </p>
          <a
            href="tel:7093711199"
            className="px-4 py-1.5 bg-[#051641] rounded text-white uppercase"
          >
            Interested
          </a>
        </div>
        <img
          src={require("../assets/driving/Learner-Detailed-Track-Course.png")}
          alt="1"
        />
      </div>
    </div>
  );
};

const AdvanceCourse = () => {
  return (
    <div className="bg-white container mx-auto shadow rounded border p-5 mb-7">
      <div className="grid sm:grid-cols-2">
        <img
          src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/driving-school/advance-course.webp"
          alt="1"
        />
        <div>
          <p className="text-[#051641] font-semibold mb-5">Advance Course</p>
          <p className="my-5">
            If you’ve never been behind the steering wheel, this course is for
            you. At the end of 21 days, you’ll know the basic traffic rules and
            have a hands-on driving experience through simulators and on-road
            driving. What’s more, you’ll have the confidence of taking the RTO
            driving exam to obtain a permanent driving license.
          </p>
          <a
            href="tel:7093711199"
            className="px-4 py-1.5 bg-[#051641] rounded text-white uppercase"
          >
            Interested
          </a>
        </div>
      </div>
    </div>
  );
};

export default DrivingSchool;
